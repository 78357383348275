/*------Стилизация вертикальной сетки для графика--------------------------------------------------------------------*/
.card-question-vertical-graph__vertical-grid {
    width: 33.33em;
    position: relative;
    display: flex;
    /* align-items: end; */
    justify-content: center;
}
.vertical-grid__position-table {
    position: absolute;
    border-bottom: 1px solid rgba(54, 59, 77, 0.05);
    max-height: 320px;
    /*width: 568px;*/
    width: 100%;
    top: 0.11em;
}
.vertical-grid__position-table td {
    border-top: 1px solid rgba(54, 59, 77, 0.05);
    border-left: 1px solid rgba(54, 59, 77, 0.05);
    height: 1.77em;
}
/*-----Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 810px) {
    .vertical-grid__position-table {
        top: 10px;
    }
}
@media (max-width: 900px) {
    .card-question-vertical-graph__vertical-grid {
        width: 100%;
        height: 240px;
    }
    .vertical-grid__position-table {
        top: -2px;
    }
    .vertical-grid__position-table td {
        height: 22px;
    }
}