.qr-logins__section {
  page-break-after: always;
}
.qr-logins {
  text-align: center;
}

.qr-logins h1 {
  font-size: 26px;
  line-height: 133%;
  font-weight: 500;
  word-wrap: break-word;
  text-align: center;
  margin: 70px auto 25px;
  max-width: 450px;
}

.qr-logins-instruction {
  font-size: 16px;
  line-height: 133%;
  word-wrap: break-word;
  text-align: center;
  margin: 0 auto 16px;
  max-width: 450px;
}

.qr-logins-link {
  font-size: 20px;
  font-weight: 500;
  line-height: 133%;
  word-wrap: break-word;
  text-align: center;
  margin: 0 auto 16px;
}

.qr-logins-pin-code {
  line-height: 133%;
  font-weight: 500;
  font-size: 32px;
  margin: 0 auto 16px;
}

.qr-logins-pin-code-mark {
  color: black;
  background: lightgray;
}

.qr-logins .qr {
  margin-bottom: 10px;
}

.qr-logins img {
  width: 300px;
  height: 300px;
}

.qr-logins__section:nth-child(2n) {
  page-break-after: always;
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
  .qr-logins h1 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .qr-logins-instruction {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .qr-logins-link {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .qr-logins img {
    width: 250px;
    height: 250px;
  }
  .qr-logins-pin-code {
    font-size: 20px;
  }
}