.details-vote-results-comment__wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid rgba(54, 59, 77, 0.2);
    border-radius: 1.33em;
    margin-top: 1.33em;
}
.details-vote-results-comment-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 4.44em;
}
.details-vote-results-comment-header__label {
    font-size: 0.88em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: rgba(54, 59, 77, 1);
    font-weight: 500;
    width: 30%;
    height: 100%;
    padding: 1.33em 0.88em 1.33em 1.33em;
}
.details-vote-results-comment-header__icon-block {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid rgba(54, 59, 77, 0.2);
    height: 100%;
    padding: 1.33em 1.33em 1.33em 1.33em;
}
.details-vote-results-comment-header__count {
    color: rgba(54, 59, 77, 1);
    font-weight: 500;
    font-size: 0.88em;
}
.details-vote-results-comment-header__icon {
    height: auto;
    width: 1.33em;
    cursor: pointer;
}
.details-vote-results-comment-header__icon.active {
    height: auto;
    max-width: 100%;
    cursor: pointer;
    transform: rotate(-180deg);
}
.details-vote-results-comment-content {
    display: none;
}
.details-vote-results-comment-content.active {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(54, 59, 77, 0.2);
    height: 100%;
}
.details-vote-results-comment-content__label {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0 0.88em 0 1.33em;
}
.details-vote-results-comment-content__name {
    color: rgba(54, 59, 77, 1);
    font-weight: 300;
    font-size: 0.88em;
}
.details-vote-results-comment-content__email {
    color: #363B4D;
    opacity: 0.6;
    font-size: 0.77em;
    margin-top: 0.33em;
}
.details-vote-results-comment-content__text-block {
    width: 70%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.33em 1.33em 1.33em 1.33em;
    border-left: 1px solid rgba(54, 59, 77, 0.2);
}
.details-vote-results-comment-content__text {
    color: rgba(54, 59, 77, 1);
    font-weight: 300;
    font-size: 0.88em;
}
.details-vote-results-comment-content__link {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    margin-top: 0.88em;
}
.details-vote-results-comment-content__link-label {
    color: #0084fe;
    font-weight: 300;
    font-size: 0.77em;
}
.details-vote-results-comment-content__link-name {
    color: rgba(54, 59, 77, 1);
    font-weight: 300;
    font-size: 0.77em;
    color: #0084fe;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 650px) {
    .details-vote-results-comment-header__label {
        padding: 8px 8px;
        font-size: 12px;
    }
    .details-vote-results-comment-header__icon-block {
        padding: 8px 8px;
    }
    .details-vote-results-comment-header__count {
        font-size: 12px;
    }
    .details-vote-results-comment-content__label {
        padding: 0 8px;
    }
    .details-vote-results-comment-content__name {
        font-size: 12px;
        line-height: 155%;
    }
    .details-vote-results-comment-content__email {
        font-size: 10px;
        line-height: 155%;
    }
    .details-vote-results-comment-content__text-block {
        padding: 8px 8px;
    }
    .details-vote-results-comment-content__text {
        font-size: 12px;
    }
}