.users-settings {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.users-settings__heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: rgba(54, 59, 77, 1);
    margin: 0 auto 1.77em 0;
}
.users-settings-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3.73em;
    padding: 0 1.33em 0 1.33em;
}
.users-settings-table-header__e-mail {
    font-weight: 500;
    line-height: 133%;
    color: #363B4D;
    opacity: 60%;
    width: 21.27em;
    margin-right: 2em;
    padding-right: 1.77em;
}
.users-settings-table-header__username-data {
    font-weight: 500;
    line-height: 133%;
    color: #363B4D;
    opacity: 60%;
    width: 21.27em;
    margin-right: 2em;
    padding-right: 1.77em;
}
.users-settings-table-header__role-admin {
    font-weight: 500;
    line-height: 133%;
    color: #363B4D;
    opacity: 60%;
    width: 10em;
    margin-right: 2em;
    padding-right: 1.77em;
}
.users-settings-table-header__role-superadmin {
    font-weight: 500;
    line-height: 133%;
    color: #363B4D;
    opacity: 60%;
    width: 10em;
    margin-right: 4.44em;
}
.users-settings-table__e-mail {
    width: 21.27em;
    line-height: 129%;
    color: #363B4D;
    opacity: 90%;
    min-height: 3.55em;
    display: flex;
    align-items: center;
    border-right: 0.5px solid rgba(54, 59, 77, 0.2);
    word-break: break-word;
    white-space: break-spaces;
    margin-right: 2em;
    padding-right: 1.77em;
}
.users-settings-table__username-data {
    width: 21.27em;
    line-height: 129%;
    color: #363B4D;
    opacity: 90%;
    min-height: 3.55em;
    display: flex;
    align-items: center;
    border-right: 0.5px solid rgba(54, 59, 77, 0.2);
    word-break: break-word;
    white-space: break-spaces;
    margin-right: 2em;
    padding-right: 1.77em;
}
.users-settings-table__role-admin {
    width: 10em;
    line-height: 129%;
    color: #363B4D;
    opacity: 90%;
    min-height: 3.55em;
    display: flex;
    align-items: center;
    border-right: 0.5px solid rgba(54, 59, 77, 0.2);
    word-break: break-word;
    white-space: break-spaces;
    margin-right: 2em;
    padding-right: 1.77em;
}
.users-settings-table__role-admin-mobile {
    display: none;
}
.users-settings-table__role-superadmin {
    width: 10em;
    line-height: 129%;
    color: #363B4D;
    opacity: 90%;
    min-height: 3.55em;
    display: flex;
    align-items: center;
    word-break: break-word;
    white-space: break-spaces;
    margin-right: 2em;
    padding-right: 1.77em;
}
.users-settings-table__role-superadmin-mobile {
    display: none;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
   .users-settings-table-header__e-mail,
   .users-settings-table__e-mail {
       width: 240px;
       font-size: 16px;
       margin-right: 8px;
       padding-right: 8px;
   }
    .users-settings-table-header__username-data,
    .users-settings-table__username-data {
        width: 240px;
        font-size: 16px;
        margin-right: 8px;
        padding-right: 8px;
    }
    .users-settings-table-header__role-admin,
    .users-settings-table__role-admin {
        width: 100px;
        font-size: 16px;
        margin-right: 8px;
        padding-right: 8px;
    }
    .users-settings-table-header__role-superadmin,
    .users-settings-table__role-superadmin {
        width: 200px;
        font-size: 16px;
        margin-right: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 950px) {
    .users-settings__heading {
        display: none;
    }
}
@media (max-width: 800px) {
    .users-settings-table-header {
        display: none;
    }
    .users-settings-table__e-mail {
        border: none;
        width: 100%;
        font-size: 14px;
        line-height: 114%;
        margin-right: 0;
        padding-right: 0;
        min-height: 0;
        margin-bottom: 16px;
        color: rgba(54, 59, 77, 0.4);
    }
    .users-settings-table__username-data {
        border: none;
        width: 100%;
        font-size: 16px;
        line-height: 114%;
        margin: 0 0 16px 0;
        padding-right: 0;
        min-height: 0;
    }
    .users-settings-table__role-admin,
    .users-settings-table__role-superadmin {
        display: none;
    }
    .users-settings-table__role-admin-mobile {
        display: flex;
        border: none;
        width: 100%;
        font-size: 14px;
        line-height: 114%;
        margin-right: 0;
        padding-right: 0;
        min-height: 0;
        margin-bottom: 16px;
        color: rgba(54, 59, 77, 0.4);
    }
    .users-settings-table__role-superadmin-mobile {
        display: flex;
        border: none;
        width: 100%;
        font-size: 14px;
        line-height: 114%;
        margin-right: 0;
        padding-right: 0;
        min-height: 0;
        margin-bottom: 16px;
        color: rgba(54, 59, 77, 0.4);
    }
}