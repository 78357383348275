/*------Основной стиль для footer-------------------------------------------------------------------------------------*/
.footer {
    /*position: absolute;*/
    width: 100%;
    left: 0;
    bottom: 0;
    font-size: 18px;
    position: relative;
}
.footer__container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    /*height: 15.4em;*/
    position: relative;
    border-top: 1px rgba(54, 59, 77, 0.1) solid;
    padding: 4.88em 0 4.88em 0;
}
/*------Контейнер для блока с логотипом------------------------------------------------------------------------------*/
.footer__logotype {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 12em;
}
.footer__logotype-logo {
    width: 8.7em;
    height: 1.7em;
    margin-bottom: 2.2em;
}
.footer__logotype p {
    font-size: 0.8em;
    line-height: 134%;
    color: #4A4F5F;
    opacity: 70%;
}
.footer__logotype-mobile {
    display: none;
}
/*------Контейнер для блока с картой сайта----------------------------------------------------------------------------*/
.footer__map-site {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 7.8em;
}
.footer__map-site-title {
    font-weight: 600;
    line-height: 110%;
    font-size: 1em;
    color: #4A4F5F;
    opacity: 80%;
    margin-bottom: 2.2em;
}
.map-site__main,
.map-site__org,
.map-site__myprofile {
    margin-bottom: 1.7em;
}
.footer__map-site p {
    line-height: 110%;
    font-size: 1em;
    color: #4A4F5F;
    opacity: 70%;
    cursor: pointer;
    text-decoration: none;
}
.votes-drop-down-list__list {
    margin: 0;
}
.map-site__votes {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.map-site__votes-icon-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.7em;
}
.map-site__votes-drop-down-list {
    display: none;
}
.map-site__votes-drop-down-list.active {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    gap: 1em;
    margin-bottom: 1.7em;
}
.map-site__votes-row {
    margin-left: 0.4em;
    width: 0.5em;
    height: 1em;
}
.map-site__votes-row.active {
    transform: rotate(180deg);
}
.map-site__users {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.map-site__users-icon-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.7em;
}
.map-site__users-drop-down-list {
    display: none;
}
.map-site__users-drop-down-list.active {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    gap: 1em;
    margin-bottom: 1.7em;
}
.map-site__users-row {
    margin-left: 0.4em;
    width: 0.5em;
    height: 1em;
}
.map-site__users-row.active {
    transform: rotate(180deg);
}
/*------Контейнер для блока с настройками-----------------------------------------------------------------------------*/
.footer__settings {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 15em;
}
.footer__settings-title {
    font-weight: 600;
    line-height: 110%;
    font-size: 1em;
    color: #4A4F5F;
    opacity: 80%;
    margin-bottom: 2.2em;
}
.settings__select-lang {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.7em;
    color: #4A4F5F;
    opacity: 70%;
}
.select-lang__type-lang {
    margin: 0 0.4em 0 0.4em;
    cursor: pointer;
    position: relative;
    max-width: 5.8em;
}
.type-lang__list {
    display: none;
}
.type-lang__list.active {
    display: flex;
    position: absolute;
    flex-direction: column;
 }
.select-lang__type-lang.active {
    width: 5.8em;
}
.select-lang__row {
    cursor: pointer;
    width: 0.5em;
    height: 1em;
}
.type-lang__en {
    display: none;
}
.type-lang__en.active {
   display: initial;
}
.type-lang__ru {
   display: none;
}
.type-lang__ru.active {
    display: initial;
}
.settings__select-fonts {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.7em;
    white-space: nowrap;
    color: #4A4F5F;
    opacity: 70%;
}
.select-fonts__type-font {
    margin: 0 0.4em 0 0.4em;
    display: flex;
    cursor: pointer;
    position: relative;
    line-height: 100%;
}
.select-fonts__row {
    cursor: pointer;
    width: 0.5em;
    height: 1em;
}
.settings__select-timezone {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.7em;
    white-space: nowrap;
    color: #4A4F5F;
    opacity: 70%;
}
.select-timezone__type-timezone {
    margin: 0 0.4em 0 0.4em;
    display: flex;
    cursor: pointer;
    position: relative;
    line-height: 110%;
}
.select-timezone__row {
    cursor: pointer;
    width: 0.5em;
    height: 1em;
}
/*------Контейнер для блока с поддержкой------------------------------------------------------------------------------*/
.footer__support {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 10em;
}
.footer__support-title {
    font-weight: 600;
    line-height: 110%;
    font-size: 1em;
    color: #4A4F5F;
    opacity: 80%;
    margin-bottom: 2.2em;
}
.footer__support a {
    color: #4A4F5F;
    line-height: 110%;
    opacity: 70%;
    cursor: pointer;
    text-decoration: none;
}
.footer__support a:nth-child(2) {
    margin-bottom: 1.7em;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .footer {
        font-size: 16px;
    }
}
@media (max-width: 1120px) {
    .footer {
        font-size: 12px;
    }
    .footer__settings {
        width: 10em;
    }
}
@media (max-width: 830px) {
    .footer {
        font-size: 10px;
    }
}
@media (max-width: 700px) {
    .footer {
        font-size: 8px;
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .footer {
        position: relative;
    }
    .footer__container {
        flex-direction: column-reverse;
        padding: 28px 16px 24px 16px;
        max-height: 832px;
    }
    .footer__logotype-logo {
        display: none;
    }
    .footer__logotype-mobile {
        display: flex;
        margin-bottom: 28px;
        width: 100%;
    }
    .footer__map-site {
        border-bottom: 1px rgba(54, 59, 77, 0.1) solid;
        margin-bottom: 24px;
        width: 100%;
    }
    .footer__settings {
        border-bottom: 1px rgba(54, 59, 77, 0.1) solid;
        margin-bottom: 24px;
        width: 100%;
    }
    .footer__support {
        border-bottom: 1px rgba(54, 59, 77, 0.1) solid;
        margin-bottom: 24px;
        width: 100%;
        padding-bottom: 16px;
    }
    .footer__logotype {
        width: 100%;
    }
    .footer__logotype p {
        font-size: 15px;
    }
    .footer__map-site p {
        font-size: 15px;
        line-height: 121%;
    }

    .footer__map-site p:nth-child(2) {
        font-size: 15px;
        line-height: 121%;
    }
    .footer__map-site p:nth-child(1) {
        font-size: 15px;
        line-height: 121%;
    }
    .footer__map-site p:nth-child(5) {
        font-size: 15px;
        line-height: 121%;
        margin-bottom: 16px;
    }
    .footer__map-site p:nth-child(6) {
        font-size: 15px;
        line-height: 121%;
    }
    .map-site__users-icon-block,
    .map-site__votes-icon-block {
        align-items: center;
    }
    .map-site__main,
    .map-site__org,
    .map-site__myprofile {
        margin-bottom: 16px;
    }
    .map-site__users-row,
    .map-site__votes-row {
        width: 8px;
        height: 8px;
    }
    .settings__select-lang {
        font-size: 15px;
        line-height: 121%;
    }
    .footer__support a {
        font-size: 15px;
        line-height: 121%;
    }
    .footer__map-site-title,
    .footer__settings-title,
    .footer__support-title {
        font-size: 16px;
        line-height: 124%;
        font-weight: 500;
        margin-bottom: 16px;
    }
    .footer__support{order: 1}
    .footer__settings{order: 2}
    .footer__map-site{order: 3}
    .footer__logotype-mobile{order: 4}
}
