.materials-vote-question__wrapper {
    /*font-size: 14px;*/
    font-weight: 400;
    color: rgba(54, 59, 77, 1);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
}
.materials-vote-question-select-arrow {
    width: 0.6em;
    height: 0.6em;
    cursor: pointer;
    margin-left: 8px;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 890px) {
    .materials-vote-question__wrapper {
        /*font-size: 11px;*/
    }
}
@media (max-width: 600px) {
    .materials-vote-question__wrapper {
        /*font-size: 14px;*/
    }
}