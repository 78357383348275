/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.add-new-vote__container {
    display: flex;
    flex-direction: column;
    min-height: calc(49vh - 80px);
    font-size: 18px;
    margin-bottom: 7em;
}
.add-new-vote__current-name-org {
    display: none;
}
.add-new-vote__current-name-org.active {
    display: initial;
    font-size: 1em;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.4);
    margin-bottom: 2.22em;
    word-break: break-word;
}
.add-new-vote__title-select-org-mobile {
    display: none;
}
.add-new-vote__add-question-button-mobile {
    display:none;
}
/*------Контейнер для блока с прогрессом------------------------------------------------------------------------------*/
.add-new-vote__progress-step {
    display: none;
}
.add-new-vote__progress-step-bar {
    width: 30%;
    height: 2px;
    background-color: #4ED4A9;
}
.add-new-vote__progress-one-step {
    position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    background-color: #FBFBFC;
}
.add-new-vote__progress-two-step {
    position: absolute;
    left: 50%;
    width: 32px;
    height: 32px;
    background-color: #FBFBFC;
}
.add-new-vote__progress-three-step {
    position: absolute;
    left: 100%;
    width: 32px;
    height: 32px;
    background-color: #FBFBFC;
}
/*------Контейнер для основного блока с контентом---------------------------------------------------------------------*/
.add-new-vote__general-settings-questions-block {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
}
/*------Контейнер для блока с выбором организации---------------------------------------------------------------------*/
.add-new-vote__select-block {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 1.33em;
    margin-bottom: 1.77em;
    padding: 1.77em 1.77em;
    border: 1px solid rgba(54, 59, 77, 0.2);
    max-width: 36.44em;
}
.add-new-vote__title-select-org {
    font-size: 1.33em;
    font-weight: 600;
    color: #363B4D;
    line-height: 133%;
    margin-bottom: 1.77em;
}
.add-new-vote__select-role {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    margin-bottom: 1.7em;
}
.add-new-vote__red-star {
    color: #FF4970;
    vertical-align: top;
}
.add-new-vote__label {
    color: rgba(54, 59, 77, 1);
    font-weight: 400;
    font-size: 1em;
    line-height: 133%;
}
.add-new-vote__time-zone-select-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 2.6em;
    margin: 0 auto;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    box-sizing: border-box;
    border-radius: 0.4em;
    cursor: pointer;
}
.add-new-vote__time-zone-select-value {
    line-height: 133%;
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
    margin: auto auto auto 1.3em;
    max-width: 20em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.add-new-vote__time-zone-select-arrow {
    width: 0.8em;
    height: 0.8em;
    object-fit: cover;
    margin: auto 1.3em auto auto;
}
.add-new-vote__time-zone-options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #FFFFFF;
    overflow-y: scroll;
    top: 3.22em;
    left: -1px;
    width: 100%;
    max-height: 210px;
    box-shadow: 0 4px 8px rgba(54, 59, 77, 0.1);
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 1.33em 1.33em;
    cursor: default;
    z-index: 9;
}
.add-new-vote__time-zone-options-container.hidden {
    display: none;
}
.add-new-vote__time-zone-options-container::-webkit-scrollbar {
    width: 4px;
    background: #FFFFFF;
    border-radius: 4px;
}
.add-new-vote__time-zone-options-container::-webkit-scrollbar-thumb {
    background: #CFD4DD;
    border-radius: 4px;
}
.add-new-vote__time-zone-option-container {
    display: flex;
    margin: 1.33em 0 0;
    width: 100%;
    color: #363B4D;
}
.add-new-vote__time-zone-option-container:hover {
    cursor: pointer;
    opacity: 0.6;
}
.add-new-vote__time-zone-option-container_disabler {
    color: #bbbcc0;
}
.add-new-vote__time-zone-option-container_disabler:hover {
    cursor: default;
    opacity: 1;
}
.add-new-vote__time-zone-option {
    line-height: 133%;
    opacity: 0.9;
    margin: 0;
    width: 100%;
}
.add-new-vote__button-block {
    display: inline-flex;
    justify-content: flex-end;
}
.add-new-vote__button-next {
    background-color:#0084fe;
    color: #FFFFFF;
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 500;
    border-radius: 4px;
    width: 8.55em;
    height: 2.66em;
    cursor: pointer;
}
.add-new-vote__button-next:hover {
    background-color: #FFFFFF;
    color:#0084fe;
    border: 1px solid#0084fe;
}
/*------Контейнер для блока общие настройки---------------------------------------------------------------------------*/
.add-new-vote__general-settings {
    width: 45.556vw;
    display: flex;
    flex-direction: column;
    padding: 1.33em 1.33em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    align-self: flex-start;
}
.add-new-vote__general-settings__mobile {
    display: none;
}
/*------Контейнер для блока c названием организации-------------------------------------------------------------------*/
.add-new-vote__name-new-vote {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.77em;
}
.add-new-vote__name-new-vote-label {
    font-size: 1em;
    line-height: 133%;
    font-weight: 400;
    color: rgba(54, 59, 77, 1);
}
.add-new-vote__name-new-vote-field {
    outline: none;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    height: 3.11em;
    padding: 0.88em 1.33em 0.88em 1.33em;
    font-size: 1em;
    color: rgba(54, 59, 77, 1);
}
.add-new-vote__name-new-vote-field::placeholder {
    color: rgba(54, 59, 77, 0.4);
}
/*------Контейнер для блока c тайным и открытым голосованием----------------------------------------------------------*/
.add-new-vote__select-open-close-vote-buttons {
    display: inline-flex;
    gap: 0.88em;
    margin-bottom: 1.77em;
    justify-content: space-between;
}
.add-new-vote__close-vote-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4em;
    border: 1px solid rgba(54, 59, 77, 0.4);
    border-radius: 4px;
    cursor: pointer;
    width: 19.167vw;
    color: rgba(54, 59, 77, 0.4);
}
.add-new-vote__close-vote-btn:hover {
    color:#0084fe;
    border: 1px solid#0084fe;
    background-color: rgba(0, 132, 254, 0.05);
}
.add-new-vote__close-vote-btn_active {
    color:#0084fe;
    border: 1px solid#0084fe;
    background-color: rgba(0, 132, 254, 0.05);
}
.add-new-vote__open-vote-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4em;
    border: 1px solid rgba(54, 59, 77, 0.4);
    border-radius: 4px;
    cursor: pointer;
    width: 19.167vw;
    color: rgba(54, 59, 77, 0.4);
}
.add-new-vote__open-vote-btn:hover {
    color:#0084fe;
    border: 1px solid#0084fe;
    background-color: rgba(0, 132, 254, 0.05);
}
.add-new-vote__open-vote-btn_active {
    color:#0084fe;
    border: 1px solid#0084fe;
    background-color: rgba(0, 132, 254, 0.05);
}
/*------Контейнер для блока c выбором дат-----------------------------------------------------------------------------*/
.add-new-vote__select-datetime-events-vote {
    display: grid;
    grid-template-columns: 20vw 20vw;
    grid-template-rows: auto;
    grid-row-gap: 1.77em;
    grid-column-gap: 0.88em;
    margin-bottom: 1.77em;
}
.add-new-vote__select-datetime {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
}
.add-new-vote__select-datetime-label {
    font-size: 1em;
    font-weight: 400;
    line-height: 133%;
    color: rgba(54, 59, 77, 1);
}
.add-new-vote__select-datetime-field {
    height: 3.11em;
    border-radius: 0.44em;
    font-size: 0.88em;
    width: 100%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid rgba(54, 59, 77, 0.4);
    padding: 0.88em 1.72em 0.88em 2.11em;
    outline: none;
    cursor: text;
}
.add-new-vote__select-datetime-field::-webkit-calendar-picker-indicator {
    cursor: pointer;
}
/*------Контейнер для блока c чекбоксом-------------------------------------------------------------------------------*/
.add-new-vote__checkboxes-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
}
.add-new-vote__checkbox-container {
    display: flex;
    margin: 1.5em auto 0 0;
    cursor: pointer;
}
.add-new-vote__checkbox-container:first-of-type {
    margin: 0 auto 0 0;
}
.add-new-vote__checkbox {
    background-image: url('../../img/checkbox-default.svg');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 1.5em;
    height: 1.5em;
    margin-right: 1em;
}
.add-new-vote__checkbox_active {
    background-image: url('../../img/checkbox-active.svg');
}
.add-new-vote__checkbox-text {
    font-size: 1em;
    line-height: 133%;
    color: #363B4D;
    opacity: 90%;
    max-width: 500px;
    margin: auto auto auto 0;
}
/*------Контейнер для блока c настройками пользователя----------------------------------------------------------------*/
.add-new-vote__user-settings-open-close-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.33em;
}
.add-new-vote__user-settings-add-users-group-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.77em;
}
.add-new-vote__settings-button-close-open-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4em;
    border: 1px solid rgba(54, 59, 77, 0.4);
    border-radius: 4px;
    cursor: pointer;
    width: 19.167vw;
    color: rgba(54, 59, 77, 0.4);
}
.add-new-vote__settings-button-close-open-list.active {
    color:#0084fe;
    border: 1px solid#0084fe;
    background-color: rgba(0, 132, 254, 0.05);
}
.add-new-vote__settings-button-close-open-list:hover {
    color:#0084fe;
    border: 1px solid#0084fe;
    background-color: rgba(0, 132, 254, 0.05);
}
.add-new-vote__user-settings-add-users-group-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
}
.add-new-vote__settings-button-add-users-group {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.66em;
    border: 1px solid#0084fe;
    border-radius: 4px;
    cursor: pointer;
    width: 19.167vw;
    color:#0084fe;
}
.add-new-vote__settings-button-add-users-group.active {
    color: #FFFFFF;
    background-color:#0084fe;
}
.add-new-vote__settings-button-add-users-group:hover {
    color: #FFFFFF;
    background-color:#0084fe;
}
.add-new-vote__open-list-block {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.77em;
}
.add-new-vote__open-list-label-input {
    font-size: 1em;
    font-weight: 400;
    line-height: 133%;
    color: rgba(54, 59, 77, 1);
}
.add-new-vote__open-list-input {
    outline: none;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    height: 3.11em;
    padding: 0.88em 1.33em 0.88em 1.33em;
    font-size: 1em;
    color: rgba(54, 59, 77, 1);
}
.add-new-vote__open-list-info {
    font-size: 0.88em;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.4);
}
.add-new-vote__weight-voting-checkbox {
    display: flex;
    flex-direction: column;
    margin: 1.77em auto 0 0;
}
.add-new-vote__weight-voting-checkbox_with-table {
    margin: 0 auto 0 0;
}
.add-new-vote__expand-list-container {
    display: flex;
    margin: 0 auto 0 0;
    cursor: pointer;
}
.add-new-vote__expand-list-container_margin {
    display: flex;
    margin: 0 auto 24px 0;
    cursor: pointer;
}
.add-new-vote__expand-list-icon {
    background-image: url('../../img/add-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 24px;
    height: 24px;
    margin: auto 8px auto 0;
}
.add-new-vote__hide-list-icon {
    background-image: url('../../img/hide-minus-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 24px;
    height: 24px;
    margin: auto 8px auto 0;
}
.add-new-vote__expand-list-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color:#0084fe;
    margin: auto auto auto 0;
}
/*------Контейнер для блока c таблицей и пагинацией-------------------------------------------------------------------*/
.add-new-vote__list-users-table {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(54, 59, 77, 0.3);
    margin-bottom: 0.88em;
    border-radius: 1.33em;
    font-size: 1em;
}
.add-new-vote__list-users-table-header {
    display: flex;
    flex-direction: row;
    border-top-left-radius: 1.33em;
    border-top-right-radius: 1.33em;

}
.add-new-vote__list-users-table-header-username {
    width: 18.11em;
    color: rgba(54, 59, 77, 1);
    line-height: 156%;
    font-weight: 400;
    font-size: 1em;
    border-right: 1px solid rgba(54, 59, 77, 0.3);
    height: 100%;
    padding: 0.88em 0 0.88em 1.33em;
}
.add-new-vote__list-users-table-header-weight-vote {
    display: flex;
    justify-content: center;
    width: 6.55em;
    color: rgba(54, 59, 77, 1);
    line-height: 156%;
    font-weight: 400;
    font-size: 1em;
    padding: 0.88em 0 0.88em 0;
    border-right: 1px solid rgba(54, 59, 77, 0.3);
}
.add-new-vote__list-users-table-header-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.77em;
    color: rgba(54, 59, 77, 1);
    line-height: 156%;
    font-weight: 400;
    font-size: 1em;
    margin: 0 auto;
}
.add-new-vote__list-users-table-row {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(54, 59, 77, 0.3);
}
.add-new-vote__table-row-username-email {
    display: flex;
    flex-direction: column;
    gap: 0.22em;
    width: 18.11em;
    word-break: break-word;
    padding: 0.88em 1.33em 0.88em 1.33em;
    border-right: 1px solid rgba(54, 59, 77, 0.3);
}
.add-new-vote__table-row-username {
    color: rgba(54, 59, 77, 1);
    font-weight: 400;
    font-size: 1em;
}
.add-new-vote__table-row-email {
    color: rgba(54, 59, 77, 0.4);
    font-size: 0.88em;
}
.add-new-vote__table-row-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.55em;
    color: rgba(54, 59, 77, 1);
    line-height: 156%;
    font-weight: 400;
    font-size: 1em;
    border-right: 1px solid rgba(54, 59, 77, 0.3);
}
.add-new-vote__table-row-count-weight-mobile {
    display: none;
}
.add-new-vote__table-row-count-number {
    color: rgba(54, 59, 77, 1);
    line-height: 156%;
    font-weight: 400;
    font-size: 1em;
    margin: 0 0.5em 0 0.5em;
}
.add-new-vote__table-row-count-minus {
    width: 0.77em;
    height: 0.77em;
    cursor: pointer;
}
.add-new-vote__table-row-count-plus {
    width: 0.77em;
    height: 0.77em;
    cursor: pointer;
}
.add-new-vote__table-row-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.77em;
    margin: auto;
}
.add-new-vote__table-row-action-delete {
    color: #FF4970;
    line-height: 156%;
    font-weight: 400;
    font-size: 1em;
    cursor: pointer;
    margin: auto;
}
.add-new-vote__table-row-action-icon-delete {
    display: none;
}
.add-new-vote__top-pagination {
    /*margin: 1.77em 0 0.88em 0;*/
}
.add-new-vote__bottom-pagination {
    margin: 0;
}
.add-new-vote__no-users-container {
    display: flex;
    border-radius: 8px;
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    margin-bottom: 0.88em;
    width: 100%;
    height: 48px;
}
.add-new-vote__no-users {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
    margin: auto;
}
/*------Контейнер для блока вопросы-----------------------------------------------------------------------------------*/
.add-new-vote__questions-block {
    display: flex;
    flex-direction: column;
}
.add-new-vote__questions {
    display: flex;
    flex-direction: column;
    padding: 1.77em 1.77em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    width: 49.444vw;
    align-self: flex-start;
}
.add-new-vote__title-questions {
   font-size: 1.33rem;
    font-weight: 600;
    line-height: 133%;
    color: #363B4D;
    margin-bottom: 1.33em;
}
.add-new-vote__title-questions-mobile {
    display: none;
}
.add-new-vote__add-question-button {
    border: 1px#0084fe dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7.55em;
    border-radius: 0.44em;
    cursor: pointer;
}
.add-new-vote__add-question-button.hidden {
    display: none;
}
.add-new-vote__icon-bnt-block {
    display: inline-flex;
    align-items: center;
    gap: 1em;
}
.add-new-vote__icon-add-question-bnt {
    width: 1.11em;
    height: 1.11em;
}
.add-new-vote__add-question-label {
    color:#0084fe;
    font-weight: 400;
    line-height: 133%;
    font-size: 1em;
}
.add-new-vote__select-type-questions.active {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.27em;
    width: 45vw;
    height: 7.55em;
}
.add-new-vote__select-type-questions-mobile {
    display: none;
}
.add-new-vote__select-type-questions {
    display: none;
}
.add-new-vote__select-type-vote-ynq,
.add-new-vote__select-type-vote-none,
.add-new-vote__select-type-vote-position_single,
.add-new-vote__select-type-vote-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.77em;
    padding: 0 1.72em 0 1.72em;
    color: rgba(54, 59, 77, 0.4);
    cursor: pointer;
    border: 1px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    width: 10.8vw;
    height: 4.7em;
    text-align: center;
}
.add-new-vote__select-type-vote-radio_grid,
.add-new-vote__select-type-vote-position_multiple,
.add-new-vote__select-type-vote-same_positions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.77em;
    padding: 0 1.72em 0 1.72em;
    color: rgba(54, 59, 77, 0.4);
    cursor: pointer;
    border: 1px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    width: 14.583vw;
    height: 4.7em;
    text-align: center;
}
.add-new-vote__select-type-vote-ynq:hover,
.add-new-vote__select-type-vote-none:hover,
.add-new-vote__select-type-vote-position_single:hover,
.add-new-vote__select-type-vote-grid:hover,
.add-new-vote__select-type-vote-radio_grid:hover,
.add-new-vote__select-type-vote-position_multiple:hover,
.add-new-vote__select-type-vote-same_positions:hover {
    color:#0084fe;
    border: 1px solid#0084fe;
    background-color: rgba(0, 132, 254, 0.05);
}
/*-----Контейнер для кнопки добавить голосование----------------------------------------------------------------------*/
.add-new-vote__add-vote-button-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 2.22em;
    gap: 0.88em;
}
.add-new-vote__add-vote-button-block-create-btn {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
}
.add-new-vote-question-type__add-vote-btn {
    width: 19.55em;
    color:#0084fe;
    background: #FFFFFF;
    border: 1px solid#0084fe;
    border-radius: 4px;
    height: 3.11em;
    font-size: 1em;
    line-height: 133%;
}
.add-new-vote-question-type__add-vote-btn.active,
.add-new-vote-question-type__save-template-btn.active,
.add-new-vote-question-type__add-vote-btn:disabled {
    background: darkgray;
    pointer-events: none;
    border: none;
    color: rgba(54, 59, 77, 0.5);
}
.add-new-vote-question-type__add-vote-btn:hover {
    color: #FFFFFF;
    background:#0084fe;
    cursor: pointer;
}
.add-new-vote-question-type__save-template-btn {
    width: 19.55em;
    color:#0084fe;
    background: #FFFFFF;
    border: 1px solid#0084fe;
    border-radius: 4px;
    height: 3.11em;
    font-size: 1em;
    line-height: 133%;
}
.add-new-vote-question-type__save-template-btn:hover {
    color: #FFFFFF;
    background:#0084fe;
    cursor: pointer;
}
.add-new-vote-question-type__add-vote-btn-error {
    /*position: absolute;*/
    /*top: 4em;*/
    font-size: 0.88em;
    color: #FF4970;
    line-height: 150%;
    font-weight: 400;
}
.add-new-vote-question-type__save-template-no-include {
    font-size: 1em;
    color: rgba(54, 59, 77, 0.4);
    border: 1px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    padding: 1em 1em;
}
.add-new-vote-question-type__save-template-no-include.hide {
    display: none;
}
/*-----Контейнер для кнопки Далее(переход в блок вопросы)-------------------------------------------------------------*/
.add-new-vote-question__go-question-block {
    width: 100%;
    display: flex;
    justify-content: center;
}
.add-new-vote-question__go-question-block-button {
    width: 100%;
    margin: 0 15px;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 500;
    background-color:#0084fe;
    cursor: pointer;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1430px) {
    .add-new-vote__container  {
        font-size: 16px;
        min-height: calc(65vh - 80px);
    }
    .add-new-vote__expand-list-icon {
        min-width: 20px;
        height: 20px;
    }
    .add-new-vote__hide-list-icon {
        min-width: 20px;
        height: 20px;
    }
    .add-new-vote__expand-list-text {
        font-size: 14px;
        line-height: 22px;
    }
}
@media (max-width: 1230px) {
    .add-new-vote__container {
        font-size: 14px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1070px) {
    .add-new-vote__container {
        font-size: 12px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1045px) {
    .add-new-vote__expand-list-icon {
        min-width: 18px;
        height: 18px;
    }
    .add-new-vote__hide-list-icon {
        min-width: 18px;
        height: 18px;
    }
    .add-new-vote__expand-list-text {
        font-size: 12px;
        line-height: 16px;
    }
}
@media (max-width: 940px) {
    .add-new-vote__container {
        font-size: 10px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 800px) {
    .add-new-vote__container {
        font-size: 8px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 650px) {
    .add-new-vote__container {
        font-size: 6px;
        min-height: calc(65vh - 80px);
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 700px) {
    .add-new-vote__container {
        min-height: calc(65vh - 140px);
        margin-bottom: 112px;
    }
    .add-new-vote__general-settings-questions-block {
        flex-direction: column;
    }
    .add-new-vote__title-select-org {
        display: none;
    }
    .add-new-vote__title-select-org-mobile {
        display: initial;
        font-size: 21px;
        font-weight: 500;
        line-height: 140%;
        color: rgba(54, 59, 77, 1);
        margin-bottom: 24px;
    }
    .add-new-vote__title-select-org {
        font-size: 21px;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 24px;
    }
    .add-new-vote__label {
        font-size: 18px;
        line-height: 133%;
    }
    .add-new-vote__select-role {
        gap: 8px;
        margin-bottom: 24px;
    }
    .add-new-vote__time-zone-select-container {
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
    }
    .add-new-vote__current-name-org.active {
       font-size: 16px;
        margin-bottom: 0;
    }
    .add-new-vote__button-next {
        width: 100%;
        height: 48px;
        border-radius: 4px;
        font-size: 16px;
    }
    .add-new-vote__progress-step {
        display: flex;
        width: 90%;
        position: relative;
        align-items: center;
        margin: 24px 0 36px 0;
        background-color: rgba(54, 59, 77, 0.2);
    }
    .add-new-vote__general-settings {
        width: 100%;
        padding: 16px 16px 16px 16px;
        border-radius: 16px;
        margin-bottom: 16px;
    }
    .add-new-vote__name-new-vote {
        margin-bottom: 24px;
    }
    .add-new-vote__name-new-vote-label {
        font-size: 16px;
    }
    .add-new-vote__name-new-vote-field {
        font-size: 16px;
        padding: 13px 16px 13px 16px;
        height: 48px;
    }
    .add-new-vote__select-open-close-vote-buttons {
        margin-bottom: 24px;
    }
    .add-new-vote__close-vote-btn,
    .add-new-vote__open-vote-btn {
        font-size: 16px;
        text-align: center;
        width: 50%;
        height: 68px;
        border-radius: 4px;
    }
    .add-new-vote__select-datetime-events-vote {
       display: flex;
       flex-direction: column;
        margin-bottom: 24px;
    }
    .add-new-vote__select-datetime {
        gap: 8px;
    }
    .add-new-vote__select-datetime-label {
        font-size: 16px;
        line-height: 138%;
    }
    .add-new-vote__select-datetime-field {
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
    }
    .add-new-vote__checkboxes-block {
        margin-bottom: 24px;
        gap: 16px;
    }
    .add-new-vote__checkbox-text {
        font-size: 16px;
    }
    .add-new-vote__user-settings-open-close-btn {
        margin-bottom: 16px;
        gap: 8px;
    }
    .add-new-vote__settings-button-close-open-list {
        width: 50%;
        height: 68px;
        font-size: 16px;
    }
    .add-new-vote__user-settings-add-users-group-btn {
        margin-bottom: 24px;
        gap: 8px;
    }
    .add-new-vote__settings-button-add-users-group {
        width: 50%;
        height: 68px;
        font-size: 16px;
        text-align: center;
    }
    .add-new-vote__weight-voting-checkbox {
        margin-bottom: 24px;
    }
    .add-new-vote__weight-voting-checkbox_with-table {
        margin-bottom: 24px;
    }
    .add-new-vote__open-list-block {
        gap: 16px;
        margin-bottom: 24px;
    }
    .add-new-vote__open-list-label-input {
        font-size: 16px;
    }
    .add-new-vote__open-list-info {
        font-size: 14px;
    }
    .add-new-vote__open-list-input {
        height: 36px;
        border-radius: 8px;
        font-size: 16px;
        padding: 13px 16px 13px 16px;
    }
    .add-new-vote__top-pagination {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .add-new-vote__list-users-table {
     border: none;
    }
   .add-new-vote__list-users-table-header {
      display: none;
    }
   .add-new-vote__list-users-table-row {
       border: none;
       gap: 11px;
       display: grid;
       grid-template-columns: 80% 1fr;
       grid-template-rows: 1fr 20%;
       grid-template-areas: "blockdownload loadbtn"
                             "success success";
       width: 100%;
       margin-bottom: 16px ;
   }
   .add-new-vote__table-row-username-email {
       grid-area: blockdownload;
        border: none;
        gap: 8px;
        width: 100%;
        padding: 0;
   }
    .add-new-vote__table-row-username {
        font-size: 16px;
        line-height: 130%;
    }
    .add-new-vote__table-row-email {
        font-size: 14px;
        line-height: 130%;
    }
    .add-new-vote__table-row-count {
        grid-area: success;
        font-size: 16px;
        border: none;
        width: 100%;
        justify-content: left;
    }
    .add-new-vote__table-row-count-weight-mobile {
        display: initial;
        margin-right: 16px;
    }
    .add-new-vote__table-row-action {
        margin: auto 0 auto auto;
        width: auto;
    }
    .add-new-vote__table-row-action-delete {
        display: none;
    }
    .add-new-vote__table-row-action-icon-delete {
        display: initial;
        width: 40px;
        height: 40px;
        margin: 0;
    }
    .add-new-vote__bottom-pagination {
        margin-bottom: 8px;
        font-size: 16px;
    }
    .add-new-vote__questions {
        width: 100%;
        padding: 24px 16px 24px 16px;
        margin-bottom: 16px;
        border-radius: 24px;
    }
    .add-new-vote__title-questions {
        display: none;
    }
    .add-new-vote__title-questions-mobile {
        display: initial;
        margin-bottom: 32px;
        font-size: 21px;
        line-height: 138%;
        font-weight: 500;
        color: rgba(54, 59, 77, 1);
    }
    .add-new-vote__add-question-button {
        height: 72px;
        border-radius: 8px;
    }
    .add-new-vote__add-question-button.hidden {
        border: 1px#0084fe dashed;
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 72px;
        border-radius: 8px;
        cursor: pointer;
    }
    .add-new-vote__icon-add-question-bnt {
        width: 20px;
        height: 20px;
    }
    .add-new-vote__add-question-label {
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
    }
    .add-new-vote__select-type-questions.active {
        flex-direction: column;
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        width: 100%;
        gap: 4px;
    }
    .add-new-vote__select-type-vote-ynq,
    .add-new-vote__select-type-vote-none,
    .add-new-vote__select-type-vote-position_single,
    .add-new-vote__select-type-vote-grid {
        width: 100%;
        border-radius: 4px;
        height: 36px;
        font-size: 16px;
        padding: 24px 16px;
    }
    .add-new-vote__select-type-vote-radio_grid,
    .add-new-vote__select-type-vote-position_multiple,
    .add-new-vote__select-type-vote-same_positions {
        width: 100%;
        border-radius: 4px;
        height: 36px;
        font-size: 16px;
        padding: 24px 16px;
    }
    .add-new-vote__add-vote-button-block {
        flex-direction: column;
        gap: 16px;
        margin: 0;
    }
    .add-new-vote-question-type__save-template-btn,
    .add-new-vote-question-type__add-vote-btn {
        width: 100%;
        height: 56px;
        font-size: 18px;
    }
    .add-new-vote-question-type__save-template-no-include {
        font-size: 18px;
        color: rgba(54, 59, 77, 0.4);
        border: 1px solid rgba(54, 59, 77, 0.4);
        border-radius: 0.44em;
        padding: 16px 16px;
    }
    .add-new-vote-question-type__add-vote-btn-error {
        top: 140px;
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
    }
    .add-new-vote__add-question-button-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px 16px 24px 16px;
        box-shadow: -1px 0 8px rgb(54 58 72 / 7%);
        background-color: #FFFFFF;
        border-radius: 24px;
        width: 100%;
        gap: 10px;
        margin-bottom: 16px;
        cursor: pointer;
    }
    .add-new-vote__add-question-button-mobile-title {
        font-size: 16px;
        line-height: 150%;
        color: rgba(54, 59, 77, 1);
        font-weight: 300;
        color: #4ED4A9;
    }
    .add-new-vote__add-question-button-mobile-icon {
        width: 20px;
        height: 20px;
    }
    .add-new-vote__time-zone-options-container {
        width: 100%;
    }
    .add-new-vote__checkbox {
        min-width: 24px;
        height: 24px;
        margin: auto 12px auto 0;
    }
    .add-new-vote__add-vote-button-block-create-btn {
        flex-direction: column;
        width: 100%;
    }
    .add-new-vote__questions-block-created-question{order: 1}
}