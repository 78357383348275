.auth-settings {
    flex-direction: column;
}
.auth-settings-type-list {
    position: relative;
}
.auth-settings-switch {
    display: flex;
    align-items: center;
    gap: 0.88rem;
}
.auth-settings-label {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #363A48;
    opacity: 0.9;
    margin: auto auto auto 0;
    cursor: pointer;
}
.auth-settings-input-block {
    margin-top: 0.88rem;
    position: relative;
}
.auth-settings-select-block {
    margin-bottom: 0;
    gap: 16px;
}
.auth-settings-option {
    font-size: 18px;
}
.auth-settings-success-icon {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: -50px;
    bottom: 6px;
}
.auth-settings-select-input {
    margin-bottom: 0;
    gap: 16px;
    width: 309px;
    height: 48px;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
}
.auth-settings-icon-row {
    width: 1rem;
    height: 1rem;
}
@media (max-width: 1120px) {
    .auth-settings-input-block {
        margin-top: 0;
    }
    .auth-settings-switch {
        margin-bottom: 8px;
    }
    .auth-settings-select-block {
        margin-top: 24px;
    }
}
@media (max-width: 450px) {
    .auth-settings-success-icon {
        display: none;
    }
}
@media (max-width: 375px) {
    .auth-settings-select-input {
        width: 100%;
    }
}
