.add-new-user-button-add-also-user.active {
    display: flex;
    font-size: 0.88em;
    color: #0084FE;
    cursor: pointer;
    line-height: 133%;
    text-decoration: underline;
}

.add-new-user-button-add-also-user {
    display: none;
}
.add-new-user-button-success-message {
    margin-bottom: 0.8em;
    font-size: 0.88em;
    color: #4ED4A9;
    line-height: 133%;
}