/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-vote-general-info__container {
    display: flex;
    flex-direction: column;
    padding: 1.77em 1.77em 2.22em 1.77em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    font-size: 18px;
    align-self: flex-start;
    width: 68.889vw;
}
.details-vote-general-info__title-name-vote {
    margin-bottom: 1.33em;
    font-size: 1.33em;
    font-weight: 600;
    color: rgba(54, 59, 77, 1);
    line-height: 133%;
    word-break: break-word;
}
.details-vote-general-info__title-name-menu {
    display: none;
}
.details-vote-general-info__main-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.33em;
}
/*------Контейнер для блока с названием организации и статусами-------------------------------------------------------*/
.details-vote-general-info__name-org-status-block {
    display: flex;
    flex-direction: column;
    margin-right: 2.66em;
    /*min-width: 29.16em;*/
}
.details-vote-general-info__name-org {
    color: rgba(54, 59, 77, 0.8);
    word-break: break-word;
    line-height: 133%;
    font-size: 1em;
    margin-bottom: 0.88em;
    max-width: 29em;
}
.details-vote-general-info__timezone-vote {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1.55em;
}
.details-vote-general-info__time-icon {
    width: 0.77em;
    height: 0.77em;
}
.details-vote-general-info__timezone-label {
    color: rgba(54, 59, 77, 0.6);
    line-height: 153%;
    font-size: 0.77em;
    padding-top: 0.16em;
}
.details-vote-general-info__status-possible-revote-block {
    display: flex;
    flex-direction: row;
}
.details-vote-general-info__current-status-vote {
    display: flex;
    flex-direction: column;
    gap: 0.44em;
    margin-right: 1.33em;
    padding-right: 1.33em;
    border-right: 1px solid rgba(54, 59, 77, 0.2);
}
.details-vote-general-info__registration-and-votes {
    font-size: 0.66em;
    line-height: 167%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid#0084fe;
    border-radius: 0.44em;
    padding: 0.11em 0.66em 0.11em 1.33em;
    white-space: nowrap;
    color:#0084fe;
    max-width: 18em;
}
.details-vote-general-info__registration {
    font-size: 0.66em;
    line-height: 167%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid #4ED4A9;
    border-radius: 0.44em;
    padding: 0.11em 0.66em 0.11em 1.33em;
    white-space: nowrap;
    color: #4ED4A9;
    max-width: 18em;
}
.details-vote-general-info__voiting {
    font-size: 0.66em;
    line-height: 167%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid #4569FF;
    border-radius: 0.44em;
    padding: 0.11em 0.66em 0.11em 1.33em;
    white-space: nowrap;
    color: #4569FF;
    max-width: 18em;
}
.details-vote-general-info__wait-voiting {
    font-size: 0.66em;
    line-height: 167%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid #FF8A00;
    border-radius: 0.44em;
    padding: 0.11em 0.66em 0.11em 1.33em;
    white-space: nowrap;
    color: #FF8A00;
    max-width: 18em;
}
.details-vote-general-info__ended-voiting {
    font-size: 0.66em;
    line-height: 167%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid #FF2957;
    border-radius: 0.44em;
    padding: 0.11em 0.66em 0.11em 1.33em;
    white-space: nowrap;
    color: #FF2957;
    max-width: 18em;
}
.details-vote-general-info__quorum_unpresant {
    font-size: 0.66em;
    line-height: 167%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid #F9C521;
    border-radius: 0.44em;
    padding: 0.11em 0.66em 0.11em 1.33em;
    white-space: nowrap;
    color: #F9C521;
    max-width: 18em;
}
.details-vote-general-info__type-vote {
    font-size: 0.66em;
    line-height: 167%;
    color: rgba(54, 59, 77, 1);
    border: 1px solid rgba(54, 59, 77, 0.6);
    border-radius: 0.44em;
    padding: 0.11em 0.66em 0.11em 1.33em;
    max-width: 8em;
    white-space: nowrap;
}
.details-vote-general-info__possible-revote-cancel-reg {
    display: flex;
    flex-direction: column;
    align-self: end;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 0.44em;
}
.details-vote-general-info__possible-label {
    color: rgba(54, 59, 77, 0.6);
    line-height: 171%;
    font-size: 0.77em;
}
.details-vote-general-info__possible-value {
    color: rgba(54, 59, 77, 1);
}
/*------Контейнер для блока с датой и временем событий----------------------------------------------------------------*/
.details-vote-general-info__datetime-block {
    display: flex;
    flex-direction: column;
    align-self: end;
    gap: 0.44em;
    padding: 0 1.77em 0 2.66em;
    border-left: 1px solid rgba(54, 59, 77, 0.2);
}
.details-vote-general-info__datetime-event {
    display: inline-flex;
    flex-direction: row;
    gap: 1em;
}
.details-vote-general-info__datetime-event-label {
    font-size: 0.77em;
    color: rgba(54, 59, 77, 0.6);
    line-height: 171%;
    white-space: nowrap;
    width: 10em;
}
.details-vote-general-info__datetime-icons-values {
    display: inline-flex;
    align-items: center;
}
.details-vote-general-info__datetime-icons-values p {
    font-size: 0.77em;
    color: rgba(54, 59, 77, 1);
    margin-right: 0.5em;
    line-height: 171%;
}
.details-vote-general-info__datetime-icons-values img {
    width: 0.72em;
    height: 0.72em;
    margin-right: 0.33em;
}
/*------Контейнер для блока с кнопкой опции продления голосования-----------------------------------------------------*/
.details-vote-general-info__option-prolong-btn {
    height: 2.66em;
    background: #FFFFFF;
    width: 19em;
    border-radius: 0.22em;
    color:#0084fe;
    cursor: pointer;
    font-size: 0.88em;
    padding: 0.66em 1.33em 0.66em 1.33em;
    border: 1px solid#0084fe;
    margin-right: 1.77em;
}
.details-vote-general-info__option-prolong-btn.active {
    pointer-events: none;
    background-color: darkgray;
}
.details-vote-general-info__option-prolong-btn:hover {
    color: #FFFFFF;
    background:#0084fe;
}
/*------Контейнер для блока с материалами голосования-----------------------------------------------------------------*/
.details-vote-general-info__materials-vote {
    margin-bottom: 1.77em;
    font-size: 0.77em;
}
/*------Контейнер для блока с кнопками--------------------------------------------------------------------------------*/
.details-vote-general-info__buttons-link-block {
    display: flex;
    flex-direction: column;
    gap: 1.33em;
}
.details-vote-general-info__buttons-block {
    display: inline-flex;
}
.details-vote-general-info__start-end-vote-btn {
    height: 2.66em;
    background: #FFFFFF;
    width: 14em;
    border-radius: 0.22em;
    color:#0084fe;
    cursor: pointer;
    font-size: 0.88em;
    padding: 0.66em 1.33em 0.66em 1.33em;
    border: 1px solid#0084fe;
    margin-right: 1.77em;
}
.details-vote-general-info__start-end-vote-btn:hover {
    color: #FFFFFF;
    background:#0084fe;
}
.details-vote-general-info__start-end-reg-btn {
    height: 2.66em;
    width: 16em;
    background: none;
    border-radius: 0.22em;
    color: #FF8A00;
    cursor: pointer;
    font-size: 0.88em;
    padding: 0.66em 1.33em 0.66em 1.33em;
    margin-right: 1.77em;
    border: 1px solid #FF8A00;
}
.details-vote-general-info__start-end-reg-btn:hover {
    background: #FF8A00;
    color: #FFFFFF;
}
.details-vote-general-info__delete-vote-btn {
    height: 2.66em;
    min-width: 12em;
    background: none;
    border-radius: 0.22em;
    color: #FF4970;
    cursor: pointer;
    font-size: 0.88em;
    padding: 0.66em 1.33em 0.66em 1.33em;
    margin-right: 1.77em;
    border: 1px solid #FF4970;
}
.details-vote-general-info__delete-vote-btn:hover {
    background: #FF4970;
    color: #FFFFFF;
}
.details-vote-general-info__start-end-vote-btn.active,
.details-vote-general-info__start-end-reg-btn.active,
.details-vote-general-info__delete-vote-btn.active {
    pointer-events: none;
    background-color: darkgray;
}
/*------Контейнер для блока с присласительной ссылкой-----------------------------------------------------------------*/
.details-vote-general-info__link-join-block {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 0.88em;
}
.details-vote-general-info__link-join-title {
    font-size: 1em;
    color: rgba(54, 59, 77, 0.8);
    font-weight: 600;
}
.details-vote-general-info__link-join {
    font-size: 1em;
    color:#0084fe;
}
.details-vote-general-info__link-icon {
    display: flex;
    flex-direction: row;
    gap: 0.44em;
    align-items: center;
}
.details-vote-general-info__icon {
    width: 1.33em;
    height: 1.33em;
    cursor: pointer;
}
.details-vote-general-info__link-join-success-text {
    position: absolute;
    bottom: -1.33em;
    font-size: 0.88em;
    color: #4ED4A9;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1420px) {
    .details-vote-general-info__container {
        font-size: 16px;
    }
}
@media (max-width: 1265px) {
    .details-vote-general-info__container{
        font-size: 14px;
    }
}
@media (max-width: 1115px) {
    .details-vote-general-info__container {
        font-size: 12px;
    }
}
@media (max-width: 960px) {
    .details-vote-general-info__container {
        font-size: 10px;
    }
}
@media (max-width: 810px) {
    .details-vote-general-info__container {
        font-size: 8px;
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 650px) {
    .details-vote-general-info__container {
        width: 100%;
        padding: 16px 16px 24px 16px;
    }
    .details-vote-general-info__title-name-menu {
        display: initial;
        margin-bottom: 16px;
        font-size: 18px;
        line-height: 144%;
        font-weight: 500;
        color: rgba(54, 59, 77, 1);
    }
    .details-vote-general-info__title-name-vote {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 8px;
    }
    .details-vote-general-info__name-org {
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 8px;
        color: rgba(54, 59, 77, 0.6);
    }
    .details-vote-general-info__name-org-status-block {
        margin: 0;
    }
    .details-vote-general-info__timezone-vote {
        margin-bottom: 16px;
    }
    .details-vote-general-info__time-icon {
        display: none;
    }
    .details-vote-general-info__timezone-label {
        font-size: 14px;
        line-height: 157%;
    }
    .details-vote-general-info__main-block {
        flex-direction: column;
        margin-bottom: 16px;
    }
    .details-vote-general-info__status-possible-revote-block {
        width: 100%;
        flex-direction: column;
    }
    .details-vote-general-info__current-status-vote {
        flex-direction: row;
        width: 100%;
        border-right: none;
        gap: 8px;
        padding: 0 0 16px 0;
        margin: 0 0 16px 0;
        border-bottom: 1px solid rgba(54, 59, 77, 0.2);
    }
    .details-vote-general-info__registration-and-votes,
    .details-vote-general-info__registration,
    .details-vote-general-info__voiting,
    .details-vote-general-info__wait-voiting,
    .details-vote-general-info__ended-voiting,
    .details-vote-general-info__quorum_unpresant {
        font-size: 12px;
        line-height: 150%;
        padding: 2px 12px 2px 12px;
        border-radius: 8px;
    }
    .details-vote-general-info__type-vote {
        font-size: 12px;
        line-height: 150%;
        padding: 2px 12px 2px 12px;
        border-radius: 8px;
    }
    .details-vote-general-info__possible-revote-cancel-reg {
        width: 100%;
        gap: 8px;
        padding: 0 0 16px 0;
        margin: 0 0 16px 0;
        border-bottom: 1px solid rgba(54, 59, 77, 0.2);
    }
    .details-vote-general-info__possible-label {
        font-size: 14px;
        line-height: 157%;
    }
   .details-vote-general-info__datetime-block {
       width: 100%;
       padding: 0;
       border: none;
       gap: 16px;
   }
   .details-vote-general-info__datetime-event {
        gap: 10px;
   }
   .details-vote-general-info__datetime-event-label {
       font-size: 14px;
       line-height: 157%;
   }
   .details-vote-general-info__datetime-icons-values img {
       width: 13px;
       height: 13px;
       margin-right: 6px;
   }
    .details-vote-general-info__datetime-icons-values p {
        font-size: 13px;
        margin-right: 6px;
    }
    .details-vote-general-info__materials-vote {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 162%;
    }
    .details-vote-general-info__buttons-block {
        flex-direction: column;
    }
    .details-vote-general-info__start-end-vote-btn {
        border-radius: 4px;
        margin: 0 0 16px 0;
        height: 48px;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }
    .details-vote-general-info__start-end-reg-btn {
        border-radius: 4px;
        margin: 0 0 16px 0;
        height: 48px;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }
    .details-vote-general-info__delete-vote-btn {
        border-radius: 4px;
        height: 48px;
        font-size: 16px;
        width: 100%;
        line-height: 150%;
    }
    .details-vote-general-info__option-prolong-btn {
        border-radius: 4px;
        height: 48px;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }
    .details-vote-general-info__link-join-block {
        gap: 16px;
    }
    .details-vote-general-info__link-join-title {
        font-size: 16px;
    }
    .details-vote-general-info__link-icon {
        gap: 12px;
    }
    .details-vote-general-info__link-join {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .details-vote-general-info__icon {
        width: 20px;
        height: 20px;
    }
    .details-vote-general-info__link-join-success-text {
        font-size: 14px
    }
}

