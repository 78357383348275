/*------Cтилизация общего блока для select, переключения между страницами и поиска в таблице -------------------------*/
.navigation-menu__pagination-search-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    /*color: rgba(54, 59, 77, 1);*/
    padding: 20px 0 32px 0;
}
/*------Cтилизация для выбора показа количества элементов-------------------------------------------------------------*/
.pagination-search-block__show-page {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    padding-right: 32px;
    height: 24px;
    letter-spacing: 0.0025em;
    cursor: pointer;
    position: relative;
}
.pagination-search-block__text {
    margin: 0 6px 0 0;
}
.pagination-search-block__arrow-count-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*gap: 12px;*/
    padding: 4px 8px 4px 4px;
    border-radius: 8px;
    border: 1px solid rgba(54, 59, 77, 0.2);
    width: 68px;
    position: relative;
}
.pagination-search-block__arrow {
    background-image: url('../../img/options-dropdown-arrow.svg');
    width: 10px;
    height: 6px;
    background-size: cover;
    margin-left: 12px;
    background-repeat: no-repeat;
}
.pagination-search-block__options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 68px;
    top: 35px;
    border: 0.5px solid #EEEEEE;
    box-shadow: 0 4px 8px rgba(54, 59, 77, 0.1);
    cursor: default;
    overflow: hidden;
    border-radius: 8px;
    z-index: 33;
}
.pagination-search-block__option-container {
    display: flex;
    height: 32px;
    background: #FFFFFF;
    cursor: pointer;
}
.pagination-search-block__option-container:last-of-type {
    border: none;
}
.pagination-search-block__option-container:hover {
    background: #cfcdcd;
}
.pagination-search-block__option {
    padding: 4px 40px 4px 8px;
}
/*------Cтилизация для переключения страниц--------------------------------------------------------------------------*/
.pagination-search-block__change-page {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-right: 40px;
    height: 24px;
}
.pagination-search-block__change-page i {
    font-weight: 100;
}
.pagination-search-block__change-page img {
    cursor: pointer;
}
.change-page__rows {
    display: flex;
    align-items: center;
    gap: 50px;
}
.change-page__counter-page {
    width: 4em;
    text-align: center;
}
/*------Cтилизация для поиска по таблице-----------------------------------------------------------------------------*/
.pagination-search-block__search-table {
    display: flex;
    gap: 12px;
    opacity: 60%;
    position: relative;
}
.pagination-search-block__search-table input {
    outline: none;
    background-color: #FFFFFF;
    height: 40px;
    box-shadow: 0 4px 8px rgba(101, 105, 117, 0.05);
    border-radius: 8px;
    /*width: 432px;*/
    font-size: 14px;
    line-height: 20px;
    color: rgba(54, 59, 77, 1);
    padding: 10px 0 10px 56px;
}
.search-table__search-table-icon {
    position: absolute;
    top: 12px;
    left: 30px;
    opacity: 0.5;
}

/*------Адаптивная версия--------------------------------------------------------------------------------------------*/
@media (max-width: 1450px) {
    .pagination-search-block__search-table input {
        width: 100%;
    }
}
@media (max-width: 1440px) {
    .navigation-menu__pagination-search-block {
        font-size: 12px;
        gap: 12px;
    }
    .pagination-search-block__text {
        font-size: 12px;
    }
    .pagination-search-block__search-table input {
        font-size: 12px;
    }
    .pagination-search-block__show-page {
        padding-right: 16px;
    }
    .pagination-search-block__change-page {
        padding-right: 24px;
    }
    .pagination-search-block__options-container {
        right: 0;
    }
    .pagination-search-block__arrow-count-page {
        font-size: 12px;
    }
    .pagination-search-block__option-container {
        font-size: 12px;
    }
}
@media (max-width: 1045px) {
    .pagination-search-block__search-table input {
        /*width: 200px;*/
    }
    .change-page__rows {
        gap: 30px;
    }
    .pagination-search-block__change-page {
        gap: 20px;
    }
}
@media (max-width: 950px) {
    .pagination-search-block__search-table {
        font-size: 10px;
    }
    .pagination-search-block__show-page {
        padding-right: 0;
    }
    .pagination-search-block__change-page {
        padding-right: 0;
        font-size: 10px;
        gap: 0;
    }
    .search-table__search-table-icon {
        left: 10px;
    }
    .pagination-search-block__search-table input {
        padding: 10px 5px 10px 36px;
        font-size: 12px;
        /*width: 100%;*/
    }
    .change-page__rows {
        gap: 15px;
    }
}
@media (max-width: 820px) {
    .pagination-search-block__search-table input {
        padding: 10px 5px 10px 36px;
    }
    .pagination-search-block__arrow-count-page {
        height: 25px;
        font-size: 10px;
        width: 54px;
    }
    .navigation-menu__pagination-search-block {
        padding-bottom: 8px;
    }
}
@media (max-width: 680px) {
    .pagination-search-block__search-table input {
        padding: 10px 5px 10px 36px;
        max-width: 250px;
    }
    .navigation-menu__pagination-search-block {
        justify-content: flex-start;
    }
    .pagination-search-block__search-table {
        width: 100%;
    }
}
@media (max-width: 420px) {
    .pagination-search-block__arrow-count-page  {
        height: 26px;
    }
    .navigation-menu__pagination-search-block {
        padding: 12px 0 12px 0;
        gap: 6px;
    }
    .search-table__search-table-icon {
        display: none;
    }
    .pagination-search-block__search-table input {
        padding: 5px 5px 5px 15px;
        height: 26px;
    }
}