/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.card-question-vertical-graph-thin-column__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    /* justify-content: end; */
    max-width: 136px;
    align-items: center;
}
/*------Стилизация колонки графика------------------------------------------------------------------------------------*/
.card-question-vertical-graph-thin-column__columns {
    display: flex;
    width: 7.55em;
    justify-content: center;
    gap: 0.22em;
    height: 97%;
}
.card-question-vertical-graph-thin-column__column-svg {
    transform: rotate(180deg);
    height: 100%;
    width: 1.55em;
}
/*------Имя колонки---------------------------------------------------------------------------------------------------*/
.card-question-vertical-graph-thin-column__name-column {
    position: absolute;
    top: 101%;
    color: rgba(54, 59, 77, 0.6);
    font-size: 0.66em;
    line-height: 135%;
    font-weight: 400;
    text-align: center;
    text-overflow: ellipsis;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
}
/*-----Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 900px) {
    .card-question-vertical-graph-thin-column__columns {
        width: 95px;
        height: 100%;
    }
    .card-question-vertical-graph-thin-column__name-column {
        font-size: 12px;
    }
    .card-question-vertical-graph-thin-column__column-svg {
        width: 16px;
    }
    .card-question-vertical-graph-thin-column__name-column {
        line-height: 16px;
    }
}