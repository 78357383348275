/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.container__general-title-allpages {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 1.7em;
}
/*------Контейнер для блока с информацией о странице------------------------------------------------------------------*/
.general-title-allpages__page-info {
    display: flex;
    flex-direction: row;
    gap: 0.8em;
    margin-bottom: 1.5em;
    align-items: center;
}
.page-info__first-letter {
    line-height: 118%;
    font-size: 0.7em;
    color: #363B4D;
    opacity: 40%;
    cursor: pointer;
    text-decoration: none;
}
.page-info__second-letter {
    line-height: 118%;
    font-size: 0.7em;
    color: #363B4D;
    opacity: 40%;
}
.general-title-allpages__row {
    width: 0.3em;
    height: 0.8em;
}
/*------Контейнер для блока с заголовком------------------------------------------------------------------------------*/
.general-title-allpages__title-name{

}
.title-name__title {
    font-size: 1.7em;
    font-weight: 600;
    color: #363B4D;
    opacity: 90%;
    line-height: 103%;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .container__general-title-allpages {
        font-size: 16px;
    }
}
@media (max-width: 1120px) {
    .container__general-title-allpages{
        font-size: 14px;
    }
}
@media (max-width: 830px) {
    .container__general-title-allpages {
        font-size: 12px;
    }
}
@media (max-width: 600px) {
    .container__general-title-allpages  {
        font-size: 10px;
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .container__general-title-allpages {
        font-size: 16px;
        margin-bottom: 14px;
    }
    .general-title-allpages__page-info {
        display: none;
    }
    .title-name__title {
        font-size: 20px;
        line-height: 113%;
        font-weight: 500;
    }
}