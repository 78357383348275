/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.container__group-users {
    display: flex;
    flex-direction: column;
    margin-bottom: 9em;
    min-height: calc(49vh - 80px);
    font-size: 18px;
}
.group-user__add-user-btn-mobile {
    display: none;
}
/*------Контейнер для блока с пагинацией и сортировкий----------------------------------------------------------------*/
.group-users__sorting-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.group-users__select-btn-mobile {
    display: none;
}
/*------Контейнер для блока таблицей----------------------------------------------------------------------------------*/
.group-users__table-group-users {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.7em;
    box-shadow: -3px 0 8px rgba(54, 58, 72, 0.1);
    border-top-left-radius:  1.33em;
    border-top-right-radius: 1.33em;
    font-size: 1em;
    background-color: #FFFFFF;
}
/*------Контейнер для блока заголовка таблицы-------------------------------------------------------------------------*/
.table-group-users__header-table-group-users {
    display: flex;
    flex-direction: row;
    border-top-left-radius: 1.33em;
    border-top-right-radius: 1.33em;
    padding: 1.11em 1.33em 1.11em 1.33em;
}
.table-group-users__checkbox_container {
    position: relative;
    width: 1.1em;
    height: 1.1em;
    cursor: pointer;
    margin-right: 2.22em;
}
.table-group-users__checkbox_container input {
    position: absolute;
    opacity: 0;
}
.table-group-users__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.1em;
    width: 1.1em;
    background-color: #FFFFFF;
    border-color:#0084fe;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;
}
.table-group-users__checkbox_container:hover input ~ .table-group-users__checkmark {
    background-color: #FFFFFF;
}
.table-group-users__checkbox_container input:checked ~ .table-group-users__checkmark {
    background-color:#0084fe;
}
.table-group-users__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.table-group-users__checkbox_container input:checked ~ .table-group-users__checkmark:after {
    display: block;
}
.table-group-users__checkbox_container .table-group-users__checkmark:after {
    left: 0.3em;
    top: 0.2em;
    width: 0.4em;
    height: 0.4em;
    border: solid white;
    border-width: 0 1px 1px 0;
    /*-webkit-transform: rotate(45deg);*/
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
._show-check {
    display: none;
}
.header-table-group-users__name-group {
    font-weight: 500;
    line-height: 133%;
    color: #363B4D;
    opacity: 0.6;
    width: 30em;
    margin-right: 2.22em;
}
.header-table-group-users__members {
    font-weight: 500;
    line-height: 133%;
    color: #363B4D;
    opacity: 0.6;
    width: 12em;
    margin-right: 2.22em;
}
.header-table-group-users__actions {
    font-weight: 500;
    line-height: 133%;
    color: #363B4D;
    opacity: 0.6;
    margin-right: 21.8em;
    width: 6em;
}
.header-table-group-users__name-group-icon-sort {
    width: 1.33em;
    height: 1.33em;
    margin-left: 0.88em;
    cursor: pointer;
}
/*------Контейнер для с блока со строками таблицы---------------------------------------------------------------------*/
.table-group-users-row {
    display: flex;
    flex-direction: row;
    padding: 1.11em 1.33em 1.11em 1.33em;
    border-top: 1px solid rgba(54, 59, 77, 0.1);
    align-items: center;
}
.table-group-users-row__name-group-name-org {
    display: flex;
    flex-direction: column;
    gap: 0.22em;
    width: 30em;
    margin-right: 2.22em;
    cursor: pointer;
}
.table-group-users-row__checkbox-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.88em;
}
.table-group-users-row__name-group {
    font-weight: 400;
    color: #363B4D;
    opacity: 90%;
    line-height: 156%;
    font-size: 1em;
    word-break: break-word;
}
.table-group-users-row__icon-red-lock {
    width: 0.88em;
    height: 1.16em;
    margin-right: 0.66em;
}
.table-group-users-row__name-org {
    font-size: 0.88em;
    line-height: 150%;
    color: #363B4D;
    opacity: 40%;
    word-break: break-word;
}
.table-group-users-row__members {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    width: 12em;
    margin-right: 2.22em;
    font-size: 1em;
    word-break: break-word;
}
.table-group-users-row__members-numbers {
    font-size: 1em;
    font-weight: 400;
    line-height: 156%;
    color: #363B4D;
    opacity: 90%;
}
.table-group-users-row__members-icon {
    width: 0.66em;
    height: 0.66em;
    display: none;
}
.table-group-users-row__members-mobile {
    display: none;
}
.table-group-users-row__buttons-block {
    display: flex;
    flex-direction: row;
    width: 26.55em;
    /*justify-content: space-between;*/
    gap: 0.88em;
}
.table-group-users-row__show-group-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
    margin-right: 21.8em;
    white-space: nowrap;
}
.table-group-users-row__show-group-icon {
    width: 1.22em;
    height: 0.83em;
}
.table-group-users-row__show-group-label {
    font-size: 0.88em;
    line-height: 150%;
    color:#0084fe;
    font-weight: 300;
}
.table-group-users-row__edit-btn {
    display: flex;
    flex-direction: row;
    border-right:  0.5px solid rgba(54, 59, 77, 0.4);
    padding-right: 0.66em;
    gap: 0.61em;
    align-items: center;
    cursor: pointer;
}
.table-group-users-row__block-unblock-btn {
    display: flex;
    flex-direction: row;
    /*padding-right: 0.66em;*/
    gap: 0.61em;
    align-items: center;
    cursor: pointer;
}
.table-group-users-row__delete-btn {
    display: flex;
    flex-direction: row;
    gap: 0.61em;
    align-items: center;
    cursor: pointer;
    padding-right: 0.66em;
    margin-right: 21.8em;
}
.table-group-users-row__icon-edit {
    width: 1em;
    height: 1em;
}
.table-group-users-row__icon-black-lock {
    width: 0.66em;
    height: 1.16em;
}
.table-group-users-row__icon-delete {
    width: 0.77em;
    height: 0.77em;
}
.table-group-users-row__label-edit-btn {
    font-size: 0.88em;
    line-height: 150%;
    color:#0084fe;
    font-weight: 300;
}
.table-group-users-row__label-block-unblock-btn {
    font-size: 0.88em;
    line-height: 150%;
    color: rgba(54, 59, 77, 1);
    font-weight: 300;
}
.table-group-users-row__label-icon-delete {
    font-size: 0.88em;
    line-height: 150%;
    color: #FF4970;
    font-weight: 300;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .container__group-users {
        font-size: 16px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1120px) {
    .container__group-users {
        font-size: 12px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 830px) {
    .container__group-users {
        font-size: 10px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 600px) {
    .container__group-users {
        font-size: 8px;
        min-height: calc(65vh - 80px);
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .container__group-users {
        margin-bottom: 0;
    }
    .group-users__table-group-users {
        border-radius: 16px;
    }
    .table-group-users__header-table-group-users {
        display: none;
    }
    .table-group-users-row:nth-child(2) {
        border-top: none;
    }
    .table-group-users-row {
        justify-content: stretch;
        flex-wrap: wrap;
        align-items: initial;
        padding: 16px 16px 16px 16px;
    }
    .table-group-users__checkbox_container {
        top: -5px;
    }
    .table-group-users__checkbox_container .table-group-users__checkmark:after {
        width: 8px;
        height: 8px;
        top: 4px;
        left: 4px;
    }
    .table-group-users__checkmark {
        width: 20px;
        height: 20px;
    }
    .table-group-users-row__icon-red-lock {
        width: 16px;
        height: 21px;
    }
    .table-group-users-row__name-group-name-org {
        width: 311px;
        margin-bottom: 16px;
        gap: 8px;
    }
    .table-group-users-row__name-group {
        font-size: 16px;
        line-height: 175%;
        font-weight: 500;
        width: 100%;
    }
    .table-group-users-row__name-org {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        width: 100%;
    }
    .table-group-users-row__members {
        width: 311px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
    }
    .table-group-users-row__members-mobile {
        display: initial;
        font-size: 14px;
        color: rgba(54, 59, 77, 0.4);
    }
    .table-group-users-row__members-icon {
        display: initial;
        width: 12px;
        height: 12px;
    }
    .table-group-users-row__members-mobile-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
    }
    .table-group-users-row__members-numbers {
        font-size: 13px;
        line-height: 162%;
        font-weight: 400;
    }
    .table-group-users-row__buttons-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "edit edit"
                             "delete delete";
        width: 100%;
        grid-row-gap: 16px;
        position: relative;
    }
    .table-group-users-row__show-group-btn {
        gap: 8px;
        position: absolute;
        bottom: -5px;
    }
    .table-group-users-row__show-group-icon {
        width: 22px;
        height: 15px;
    }
    .table-group-users-row__show-group-label {
        font-size: 16px;
    }
    .table-group-users-row__edit-btn {
        grid-area: edit;
        border: none;
        height: 48px;
        justify-content: center;
        background-color:#0084fe;
        border-radius: 4px;
    }
    .table-group-users-row__label-edit-btn {
        font-size: 14px;
        color: #FFFFFF;
    }
    .table-group-users-row__block-unblock-btn {
        grid-area: blockbtn;
        height: 48px;
        border: 1px solid rgba(54, 59, 77, 1);
        border-radius: 4px;
        cursor: pointer;
        justify-content: center;
        display: none;
    }
    .table-group-users-row__label-block-unblock-btn {
        font-size: 14px;
        color: rgba(54, 59, 77, 1);
    }
    .table-group-users-row__delete-btn {
        grid-area: delete;
        height: 48px;
        border: 1px solid #FF4970;
        border-radius: 4px;
        cursor: pointer;
        justify-content: center;
        margin: 0;
    }
    .table-group-users-row__label-icon-delete {
        font-size: 14px;
        color: #FF4970;
    }
    .table-group-users-row__icon-black-lock,
    .table-group-users-row__icon-delete,
    .table-group-users-row__icon-edit {
        display: none;
    }
    .group-users__pagination {
        /*display: none;*/
    }
    .group-user__add-user-btn-mobile {
        display: block;
        width: 246px;
        height: 40px;
        margin-bottom: 16px;
        cursor: pointer;
        border: 1px solid#0084fe;
        border-radius: 4px;
        background-color: #FFFFFF;
        color:#0084fe;
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
    }
    .group-users__select-btn-mobile {
        display: inherit;
        font-size: 15px;
        line-height: 147%;
        color: #363B4D;
        opacity: 90%;
        cursor: pointer;
    }
    ._hidden-check {
        display: none;
    }
    ._show-check {
        display: initial;
    }
}