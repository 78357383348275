/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.container__add-new-group-users {
    display: flex;
    flex-direction: column;
    min-height: calc(49vh - 80px);
    font-size: 18px;
}
.add-new-group-users__current-name-org {
    display: none;
}
.add-new-group-users__current-name-org.active {
    display: initial;
    font-size: 1em;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.4);
    margin-bottom: 2.22em;
}
/*------Контейнер для блока с выбором организации---------------------------------------------------------------------*/
.add-new-group-users__select-organization-block {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 1.33em;
    margin-bottom: 6.22em;
    padding: 1.33em 1.33em;
    width: 45.556vw;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
}
.select-organization-block__title-select-org {
    font-size: 1.33em;
    font-weight: 600;
    color: #363B4D;
    line-height: 133%;
    margin-bottom: 1.77em;
}
.select-organization-block__select-role {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    margin-bottom: 1.7em;
}
.select-organization-block__red-star {
    color: #FF4970;
}
.select-organization-block__label {
    color: rgba(54, 59, 77, 1);
    font-weight: 400;
    font-size: 1em;
    line-height: 133%;
}
.select-organization-block__time-zone-select-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 2.6em;
    margin: 0 auto;
    border: 0.5px solid rgba(54, 59, 77, 0.3);
    box-sizing: border-box;
    border-radius: 0.4em;
    cursor: pointer;
}
.select-organization-block__time-zone-select-value {
    line-height: 133%;
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
    margin: auto auto auto 1.3em;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.select-organization-block__time-zone-select-arrow {
    width: 0.8em;
    height: 0.8em;
    object-fit: cover;
    margin: auto 1.3em auto auto;
}
.select-organization-block__button-block {
    display: inline-flex;
    justify-content: flex-end;
}
.select-organization-block__button-next {
    background-color:#0084fe;
    color: #FFFFFF;
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 500;
    border-radius: 4px;
    width: 8.55em;
    height: 2.66em;
    cursor: pointer;
}
.select-organization-block__button-next:hover {
    background-color: #FFFFFF;
    color:#0084fe;
    border: 1px solid#0084fe;
}
/*------Контейнер для блока с формой добавления группы-----------------------------------------------------------------*/
.add-new-group-users {
    display: flex;
    flex-direction: column;
    margin-bottom: 6.2em;
    background-color: #FFFFFF;
    box-shadow: -3px 0 8px rgba(54, 58, 72, 0.1);
    border-radius: 0.8em;
    padding: 1.77em 1.77em;
}
/*------Контейнер для блока с формой добавления анонимных пользователей-----------------------------------------------*/
.add-new-group-users-add-anonyous-users {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-top: 1em;
}
.add-new-group-users-add-anonyous-users__title {
    line-height: 110%;
    font-weight: 400;
    color: #363b4d;
    opacity: 90%;
}
.add-new-group-users-add-anonyous-users__form {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
    align-items: center;
}
.add-new-group-users-add-anonyous-users__form-input-counter {
    line-height: 110%;
    height: 3.1em;
    outline: none;
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    border-radius: 0.44em;
    padding: 0.66em 1.33em 0.66em 1.33em;
    color: #363b4d;
    opacity: 90%;
    font-size: 1em;
    margin-top: 10px;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .container__add-new-group-users  {
        font-size: 16px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1120px) {
    .container__add-new-group-users {
        font-size: 12px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 830px) {
    .container__add-new-group-users {
        font-size: 10px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 800px) {
    .add-new-group-users-add-anonyous-users__title {
        font-size: 16px;
        line-height: 114%;
    }
    .add-new-group-users-add-anonyous-users__form-input-counter  {
        width: 100%;
        height: 56px;
        font-size: 18px;
        line-height: 110%;
        margin: 0 auto 8px 0;
        max-width: 30%;
    } 
}
@media (max-width: 600px) {
    .container__add-new-group-users  {
        font-size: 8px;
        min-height: calc(65vh - 80px);
    }
    .add-new-group-users__select-organization-block {
        width: 100%;
        padding: 16px 16px 16px 16px;
        border-radius: 16px;
        margin-bottom: 16px;
    }
    .select-organization-block__title-select-org {
        display: initial;
        font-size: 21px;
        font-weight: 500;
        line-height: 140%;
        color: rgba(54, 59, 77, 1);
        margin-bottom: 24px;
    }
    .select-organization-block__label {
        font-size: 18px;
        line-height: 133%;
    }
    .select-organization-block__button-next {
        width: 100%;
        height: 48px;
        border-radius: 4px;
        font-size: 16px;
    }
}
@media (max-width: 420px) {
    .add-new-group-users-add-anonyous-users__form  {
        flex-direction: column;
    }
    .add-new-group-users-add-anonyous-users__form-input-counter {
        max-width: 100%;
    }
}    