.loader {
  display: flex;
  align-items: center;
}

.loader__bar {
  display: inline-block;
  width: 3px;
  height: 10px;
  background-color: rgba(#373c4e, 0.5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.loader__bar:nth-child(2) {
  height: 25px;
  margin: 0 5px;
  animation-delay: 0.25s;
}

.loader__bar:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #373c4e;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}

@keyframes scale-up4-blue {
  20% {
    background-color: #373c4e;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}

.loader_lg {
  padding: 25px;
  justify-content: center;
}