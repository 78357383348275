/*------Основной стиль-----------------------------------------------------------------------------------------------*/
.checkbox-status-vote {
    display: inline-flex;
    align-content: center;
}
/*------Стилизация чебокса--------------------------------------------------------------------------------------------*/
.checkbox-status-vote_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25em;
    width: 1.25em;
    background-color: #FFFFFF;
    border-color:#0084fe;
    border-style: solid;
    border-width: 1px;
}
.checkbox-status-vote_container{
    display: block;
    position: relative;
    padding-left: 2.12em;
    cursor: pointer;
    font-size: 1.12em;
    user-select: none;
}
.checkbox-status-vote_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkbox-status-vote_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1em;
    width: 1em;
    border: 1px solid#0084fe;;
}
.checkbox-status-vote_container input:checked ~ .checkbox-status-vote_checkmark {
    background-color:#0084fe;
}
.checkbox-status-vote_checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkbox-status-vote_container input:checked ~ .checkbox-status-vote_checkmark:after {
    display: block;
}
.checkbox-status-vote_container .checkbox-status-vote_checkmark:after {
    left: 0.24em;
    top: 0.10em;
    width: 0.5em;
    height: 0.5em;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/*-----Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 790px) {
    .checkbox-status-vote span {
        background: #FFFFFF;
        font-size: 14px;
        line-height: 24px;
        color: #363B4D;
    }
    .checkbox-status-vote_checkmark {
        top: 4px;
    }
}