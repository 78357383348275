/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-vote-general-info-option-prolong-vote-modal__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 56;
    width: 100%;
    height: 100vh;
    background: rgba(140, 140, 143, 0.2);
    display: flex;
}
.details-vote-general-info-option-prolong-vote-modal__container.hide {
    display: none;
}
/*------Контейнер для блока с контентом-------------------------------------------------------------------------------*/
.details-vote-general-info-option-prolong-vote-modal {
    width: 35em;
    display: flex;
    flex-direction: column;
    padding: 1.33em 1.33em 1.33em 1.33em;
    box-shadow: 0 8px 22px rgba(188, 188, 188, 0.25);
    background-color: #FFFFFF;
    border-radius: 0.22em;
    position: fixed;
    max-height: 100%;
    /*overflow: auto;*/
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.details-vote-general-info-option-prolong-vote-modal__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.33em;
}
.details-vote-general-info-option-prolong-vote-modal__title-name {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1em;
    margin-right: 2.05em;
}
.details-vote-general-info-option-prolong-vote-modal__title-icon {
    width: 0.88em;
    height: 0.88em;
    cursor: pointer;
}
.details-vote-general-info-option-prolong-vote-modal__text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.88em;
    margin-bottom: 1.33em;
}
.details-vote-general-info-option-prolong-vote-modal__variant-interval {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.33em;
}
.details-vote-general-info-option-prolong-vote-modal__variant-interval-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.88em;
}
.details-vote-general-info-option-prolong-vote-modal__variant-interval-select-type {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
    justify-content: space-between;
}
.details-vote-general-info-option-prolong-vote-modal__select-type-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.88em;
}
.details-vote-general-info-option-prolong-vote-modal__select-type-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.88em;
}
.details-vote-general-info-option-prolong-vote-modal__select-type-checkbox {
    width: 1.33em;
    height: 1.33em;
    cursor: pointer;
}
.details-vote-general-info-option-prolong-vote-modal__buttons {
    display: flex;
    justify-content: space-between;
    gap: 0.88em;
}
.details-vote-general-info-option-prolong-vote-modal__dont-save-btn {
    font-size: 1em;
    height: 3em;
    cursor: pointer;
    font-weight: 500;
    padding: 0.5em 0.88em 0.5em 0.88em;
    border-radius: 0.22em;
    background: #FFFFFF;
    color: #1890FF;
    border: 1px solid #1890FF;
}
.details-vote-general-info-option-prolong-vote-modal__dont-save-btn.active {
    pointer-events: none;
    background-color: darkgray;
}
.details-vote-general-info-option-prolong-vote-modal__dont-save-btn:hover {
    background: #1890FF;
    color: #FFFFFF;
}
.details-vote-general-info-option-prolong-vote-modal__save-btn {
    font-size: 0.88em;
    height: 2.22em;
    cursor: pointer;
    font-weight: 500;
    padding: 0.5em 0.88em 0.5em 0.88em;
    border-radius: 0.22em;
    background: #1890FF;
    color: #FFFFFF;
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .details-vote-general-info-option-prolong-vote-modal__container {
        background: none;
    }
    .details-vote-general-info-option-prolong-vote-modal {
        width: 365px;
        margin: 0 auto;
        padding: 16px 16px;
    }
    .details-vote-general-info-option-prolong-vote-modal__title {
        margin-bottom: 16px;
    }
    .details-vote-general-info-option-prolong-vote-modal__title-name {
        font-size: 18px;
    }
    .details-vote-general-info-option-prolong-vote-modal__title-icon {
        width: 12px;
        height: 12px;
    }
    .details-vote-general-info-option-prolong-vote-modal__dont-save-btn {
        height: 38px;
        font-size: 16px;
    }
    .details-vote-general-info-option-prolong-vote-modal__variant-interval-label {
        font-size: 14px;
    }
    .details-vote-general-info-option-prolong-vote-modal__select-type-label {
        font-size: 14px;
    }
    .details-vote-general-info-option-prolong-vote-modal__variant-interval-select-type {
        flex-direction: column;
        gap: 12px;
    }
    .details-vote-general-info-option-prolong-vote-modal__variant-interval {
        margin-bottom: 14px;
    }
}