.change-protocol-container {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
}
.change-protocol__recreate-protocol-btn {
    min-width: 180px;
    height: 3em;
    border-radius: 0.44em;
    background: none;
    font-size: 0.88em;
    border: 1px solid#0084fe;
    color: #0084fe;
    cursor: pointer;
}
.change-protocol__recreate-protocol-btn:hover {
    background-color: #0084fe;
    color: #FFFFFF;
}
.change-protocol__time-zone-select-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 2.6em;
    margin: 0 auto;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    box-sizing: border-box;
    border-radius: 0.4em;
    cursor: pointer;
}
.change-protocol__time-zone-select-value {
    line-height: 133%;
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
    margin: auto auto auto 1.3em;
    max-width: 20em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.change-protocol__time-zone-select-arrow {
    width: 0.8em;
    height: 0.8em;
    object-fit: cover;
    margin: auto 1.3em auto auto;
}
.change-protocol__time-zone-options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #FFFFFF;
    overflow-y: scroll;
    top: 3.22em;
    left: -1px;
    width: 100%;
    max-height: 210px;
    box-shadow: 0 4px 8px rgba(54, 59, 77, 0.1);
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 1.33em 1.33em;
    cursor: default;
    z-index: 9;
}
.change-protocol__time-zone-options-container.hidden {
    display: none;
}
.change-protocol__time-zone-options-container::-webkit-scrollbar {
    width: 4px;
    background: #FFFFFF;
    border-radius: 4px;
}
.change-protocol__time-zone-options-container::-webkit-scrollbar-thumb {
    background: #CFD4DD;
    border-radius: 4px;
}
.change-protocol__time-zone-option-container {
    display: flex;
    margin: 1.33em 0 0;
    width: 100%;
    color: #363B4D;
}
.change-protocol__time-zone-option-container:hover {
    cursor: pointer;
    opacity: 0.6;
}
.change-protocol__time-zone-option-container_disabler {
    color: #bbbcc0;
}
.change-protocol__time-zone-option-container_disabler:hover {
    cursor: default;
    opacity: 1;
}
.change-protocol__time-zone-option {
    line-height: 133%;
    opacity: 0.9;
    margin: 0;
    width: 100%;
}
@media (max-width: 960px) {
    .change-protocol__time-zone-select-container {
        width: 25em;
        margin: 0;
    }
    .change-protocol__recreate-protocol-btn {
        width: 18.66em;
        min-width: 0;
    }
}
@media (max-width: 650px) {
    .change-protocol-container{
        flex-direction: column;
    }
    .change-protocol__recreate-protocol-btn {
        width: 100%;
        height: 40px;
        font-size: 12px;
    }
    .change-protocol__time-zone-select-container {
        width: 100%;
        height: 40px;
        font-size: 12px;
    } 
}