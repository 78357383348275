.subscription-settings {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.subscription-settings-not-date-subscription {
    display: block;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    color: rgba(54, 59, 77, 1);
    opacity: 60%;
    margin: 0 auto;
}
.subscription-settings-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.33em;
}
.subscription-settings-header__status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.88em;
}
.subscription-settings-header__status-icon {

}
.subscription-settings-header__select-option {
    width: 12.77em;
    font-size: 18px;
}
.subscription-settings-header__select-option-not-active {
    width: 12.77em;
    font-size: 18px;
    pointer-events: none;
    opacity: 0.4;
}
.subscription-settings__heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: rgba(54, 59, 77, 1);
}
.subscription-settings-header__lock-icon {
    cursor: pointer;
}
.subscription-settings-general-settings {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.subscription-settings-general-settings.active {
    width: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    opacity: 0.7;
}
.subscription-settings__date-main-continer {
    display: flex;
    margin: 16px auto 0 0;
}
.subscription-settings__date-continer {
    display: flex;
    flex-direction: column;
    margin: 0 24px auto 0;
}
.subscription-settings__date-continer:last-of-type {
    margin: 0 auto 0 0;
}
.subscription-settings__date-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #363B4D;
    opacity: 0.9;
    margin: 0 auto 0 0;
}
.subscription-settings__date-input {
    outline: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
    background: #FFFFFF;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
    width: 309px;
    height: 48px;
    cursor: text;
    margin: 16px auto 0 0;
    padding: 0 24px;
}
.subscription-settings__date-input::-webkit-calendar-picker-indicator {
   cursor: pointer;
}
.subscription-settings__permissions-main-container {
    display: flex;
    margin: 24px auto 0 0;
}
.subscription-settings__permission-container {
    display: flex;
    flex-direction: column;
    margin: 0 24px 0 0;
}
.subscription-settings__permission-container:last-of-type {
    margin: 0 auto 0 0;
}
.subscription-settings__permission-heading {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    margin: 0 auto 0 0;
}
.subscription-settings__permission-input {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
    background: #FFFFFF;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
    width: 309px;
    height: 48px;
    padding: 0 24px;
    margin: 16px auto 0 0;
}
.subscription-settings__permission-input::-webkit-outer-spin-button,
.subscription-settings__permission-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.subscription-settings__permission-input,
.subscription-settings__permission-input:hover,
.subscription-settings__permission-input:focus {
    appearance: none;
    outline: none;
    -moz-appearance: textfield;
}
.subscription-settings__message-container {
    display: flex;
    flex-direction: column;
    margin: 24px auto 0 0;
}
.subscription-settings__message-container.active {
    display: flex;
    flex-direction: column;
    margin: 24px auto 0 0;
    pointer-events: none;
    opacity: 0.7;
}
.subscription-settings__message-checkbox-container {
    display: flex;
    margin: 0 auto 0 0;
    cursor: pointer;
}
.subscription-settings__message-checkbox {
    background-image: url('../../img/checkbox-default.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: auto 16px auto 0;
}
.subscription-settings__message-checkbox_active {
    background-image: url('../../img/checkbox-active.svg');
}
.subscription-settings__message-checkbox-not-check {
    background-image: url('../../img/checkbox-no-active-empty.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: auto 16px auto 0;
}
.subscription-settings__message-checkbox_active-not-check {
    background-image: url('../../img/checkbox-no-active.svg');
}
.subscription-settings__message-checkbox-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #363A48;
    opacity: 0.9;
    margin: auto auto auto 0;
}
.subscription-settings__message-input-container {
    display: flex;
    flex-direction: column;
    margin: 16px auto 0 0;
}
.subscription-settings__message-input-heading {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    margin: 0 auto 0 0;
}
.subscription-settings__message-input {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    opacity: 0.9;
    background: #FFFFFF;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
    width: 309px;
    height: 48px;
    padding: 0 24px;
    margin: 16px auto 0 0;
}
.subscription-settings__message-input::-webkit-outer-spin-button,
.subscription-settings__message-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.subscription-settings__message-input,
.subscription-settings__message-input:hover,
.subscription-settings__message-input:focus {
    appearance: none;
    outline: none;
    -moz-appearance: textfield;
}
.subscription-settings-save-changes-button-errpr-message {
    display: flex;
    position: relative;
}
.subscription-settings__error-message {
    position: absolute;
    color: #FF4970;
    font-size: 0.88em;
    bottom: -1.33em;
}
.error-message-color-green {
    color: #4ed4a9;
}
.subscription-settings__save-changes-button {
    width: 222px;
    height: 48px;
    border: 1px solid #0084fe;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color:#0084fe;
    margin: 32px auto 0 0;
}
.subscription-settings__save-changes-button:hover {
    background-color: #0084fe;
    color: #FFFFFF;
    cursor: pointer;
}
@media (max-width: 1120px) {
    .subscription-settings__date-main-continer {
        flex-direction: column;
    }
    .subscription-settings__date-continer {
        margin: 0 auto 0 0;
    }
    .subscription-settings__date-continer:last-of-type {
        margin: 24px auto 0 0;
    }
    .subscription-settings__permissions-main-container {
        flex-direction: column;
    }
    .subscription-settings__permission-container {
        margin: 0 auto 0 0;
    }
    .subscription-settings__permission-container:last-of-type {
        margin: 24px auto 0 0;
    }
    .subscription-settings__save-changes-button {
        margin: 32px auto 16px 0;
    }
    .subscription-settings__error-message {
        font-size: 14px;
        margin-bottom: 15px;
    }
}
@media (max-width: 950px) {
    .subscription-settings__heading {
        display: none;
    }
    .subscription-settings-header {
        flex-direction: column;
        gap: 16px;
    }
    .subscription-settings__error-message {
        font-size: 12px;
        margin-bottom: 5px;
    }
}
@media (max-width: 375px) {
    .subscription-settings__date-main-continer {
        margin: 0 auto;
        width: 100%;
    }
    .subscription-settings__date-continer {
        margin: 0 auto;
        width: 100%;
    }
    .subscription-settings__date-continer:last-of-type {
        margin: 24px auto 0;
    }
    .subscription-settings__date-text {
        margin: 0 auto 0 0;
    }
    .subscription-settings__date-input {
        width: 100%;
        margin: 16px auto 0;
        padding: 0 16px;
    }
    .subscription-settings__permissions-main-container {
        margin: 24px auto 0;
        width: 100%;
    }
    .subscription-settings__permission-container {
        margin: 0 auto;
        width: 100%;
    }
    .subscription-settings__permission-container:last-of-type {
        margin: 24px auto 0;
    }
    .subscription-settings__permission-input {
        width: 100%;
        padding: 0 16px;
        margin: 16px auto 0;
    }
    .subscription-settings__message-container {
        margin: 24px auto 0;
        width: 100%;
    }
    .subscription-settings__message-input-container {
        margin: 16px auto 0;
        width: 100%;
    }
    .subscription-settings__message-input {
        width: 100%;
        padding: 0 16px;
        margin: 16px auto 0;
    }
    .subscription-settings__save-changes-button {
        width: 100%;
        margin: 32px auto 16px auto;
    }
}