.subscription-settings-table {
  display: flex;
    flex-direction: column;
    margin-top: 24px;
}
.subscription-settings-table__header {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    margin-bottom: 16px;
}
.subscription-settings-table .css-1ygcj2i-MuiTableCell-root {
    font-weight: 600;
}
.subscription-settings-table .css-1ndpvdd-MuiTableCell-root {
    font-weight: 600;
}
.subscription-settings-table-checkbox {
    margin: 0 auto;
}