.add-materials {
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid rgba(54, 59, 77, 0.2);
    padding-bottom: 32px;
    margin: 0 auto 32px 0;
    width: 100%;
}
.add-materials__button-container {
    display: flex;
    margin: 0 auto 0 0;
    cursor: pointer;
}
.add-materials__button-icon {
    background-image: url('../../img/add-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 24px;
    height: 24px;
    margin: auto 8px auto 0;
}
.add-materials__button-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color:#0084fe;
    margin: auto auto auto 0;
}
.add-materials__main-container {
    display: flex;
    flex-direction: column;
    margin: 24px auto 0 0;
    width: 100%;
}
.add-materials__heading-container {
    display: flex;
    margin: 0 auto 0 0;
    width: 100%;
}
.add-materials__heading-input-container {
    display: flex;
    margin: auto 16px auto 0;
    width: 100%;
}
.add-materials__heading-input {
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    padding: 0 16px;
    margin: auto 8px auto 0;
}
.add-materials__heading-input:focus {
    outline: none;
}
.add-materials__heading-input-red-star {
    color: #FF4970;
    margin: 0 auto auto 0;
}
.add-materials__delete-container {
    display: flex;
    margin: auto auto auto 0;
    cursor: pointer;
}
.add-materials__delete-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: #FF4970;
    margin: auto 4px auto 0;
}
.add-materials__delete-icon {
    background-image: url('../../img/delete-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: auto auto auto 0;
}
.add-materials__delete-container-mobile {
    display: none;
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    border: 1px solid #FF4970;
    border-radius: 4px;
    margin: 16px auto 0;
}
.add-materials__delete-text-mobile {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FF4970;
    margin: auto;
}
.add-materials__select-container {
    display: flex;
    margin: 24px auto 0 0;
    width: 100%;
}
.add-materials__select-types-container {
    display: flex;
    margin: auto 24px auto 0;
    position: relative;
    min-width: 140px;
    height: 48px;
    background: #FFFFFF;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
    cursor: pointer;
}
.add-materials__select-types-value {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    margin: auto auto auto 16px;
}
.add-materials__select-types-arrow {
    background-image: url('../../img/drop-down-arrow.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin: auto 10px auto auto;
}
.add-materials__types-container {
    display: none;
    flex-direction: column;
    position: absolute;
    left: -1px;
    top: 56px;
    width: 140px;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
    box-sizing: border-box;
    background: #FFFFFF;
    overflow: hidden;
    z-index: 1;
    cursor: default;
}
.add-materials__types-container_active {
    display: flex;
}
.add-materials__type-container {
    display: flex;
    border-bottom: 0.5px solid rgba(54, 59, 77, 0.6);
    box-sizing: border-box;
    background: #FFFFFF;
    cursor: pointer;
    height: 48px;
}
.add-materials__type-container:hover {
    background: #ecebeb;
}
.add-materials__type-container:last-of-type {
    border-bottom: none;
}
.add-materials__type {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    margin: auto auto auto 16px;
}
.add-materials__link-input {
    width: 65.4%;
    height: 48px;
    background: #FFFFFF;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 1);
    padding: 0 16px;
    margin: auto 137px auto 0;
}
.add-materials__link-input:focus {
    outline: none;
}
.add-materials__file-add-container {
    display: flex;
    position: relative;
    margin: 0 auto 0 0;
    width: 100%;
}
.add-materials__file-add-input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
.add-materials__file-add-input-container {
    display: flex;
    cursor: pointer;
    margin: 0 16px 0 0;
    width: 81%;
}
.add-materials__file-add-input-file-name-container {
    display: flex;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 0.5px solid rgba(54, 59, 77, 0.6);
    border-right: 0;
    border-radius: 8px 0px 0px 8px;
}
.add-materials__file-add-input-file-name-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.4);
    opacity: 0.9;
    margin: auto auto auto 24px;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.add-materials__file-add-input-file-name-text_selected {
    color: rgba(54, 59, 77, 1);
}
.add-materials__file-add-input-button {
    display: flex;
    width: 130px;
    height: 48px;
    background: #FFFFFF;
    border: 0.5px solid#0084fe;
    border-radius: 0px 8px 8px 0px;
}
.add-materials__file-add-input-button-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#0084fe;
    opacity: 0.9;
    margin: auto;
}
.add-materials__success-container {
    display: flex;
    margin: auto 61px auto 0;
    min-width: 60px;
}
.add-materials__success-icon {
    background-image: url('../../img/success-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    margin: auto 4px auto 0;
}
.add-materials__success-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #4ED4A9;
    opacity: 0.9;
    margin: auto auto auto 0;
}
@media (max-width: 1855px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 280px;
    }
}
@media (max-width: 1805px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 200px;
    }
}
@media (max-width: 1550px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 140px;
    }
}
@media (max-width: 1430px) {
    .add-materials__button-icon {
        min-width: 20px;
        height: 20px;
    }
    .add-materials__button-text {
        font-size: 14px;
        line-height: 22px;
    }
    .add-materials__main-container {
        margin: 20px auto 0 0;
    }
    .add-materials__heading-container {
        width: auto;
    }
    .add-materials__heading-input-container {
        width: auto;
    }
    .add-materials__heading-input {
        width: 310px;
        height: 43px;
        font-size: 14px;
        line-height: 20px;
        padding: 0 10px;
    }
    .add-materials__delete-text {
        font-size: 14px;
        line-height: 16px;
    }
    .add-materials__delete-icon {
        width: 20px;
        height: 20px;
    }
    .add-materials__select-container {
        width: auto;
    }
    .add-materials__select-types-container {
        margin: auto 16px auto 0;
        min-width: 100px;
        height: 43px;
    }
    .add-materials__select-types-value {
        font-size: 12px;
        line-height: 16px;
        margin: auto auto auto 8px;
    }
    .add-materials__select-types-arrow {
        width: 12px;
        height: 12px;
        margin: auto 8px auto auto;
    }
    .add-materials__types-container {
        top: 48px;
        width: 100px;
    }
    .add-materials__type-container {
        height: 43px;
    }
    .add-materials__type {
        font-size: 12px;
        line-height: 16px;
        margin: auto auto auto 8px;
    }
    .add-materials__link-input {
        width: 193px;
        height: 43px;
        font-size: 14px;
        line-height: 16px;
        padding: 0 8px;
        margin: auto auto auto 0;
    }
    .add-materials__file-add-container {
        width: auto;
    }
    .add-materials__file-add-input-container {
        margin: 0 8px 0 0;
        width: 193px;
    }
    .add-materials__file-add-input-file-name-container {
        width: 125px;
        height: 43px;
    }
    .add-materials__file-add-input-file-name-text {
        font-size: 14px;
        line-height: 16px;
        margin: auto auto auto 8px;
        max-width: 100px;
    }
    .add-materials__file-add-input-button {
        width: 70px;
        height: 43px;
    }
    .add-materials__file-add-input-button-text {
        font-size: 12px;
        line-height: 14px;
    }
    .add-materials__success-container {
        margin: auto auto auto 0;
        min-width: auto;
    }
    .add-materials__success-icon {
        width: 12px;
        height: 12px;
    }
    .add-materials__success-label {
        font-size: 12px;
        line-height: 14px;
    }
}
@media (max-width: 1045px) {
    .add-materials {
        padding-bottom: 24px;
        margin: 0 auto 24px auto;
        width: 100%;
    }
    .add-materials__button-icon {
        min-width: 18px;
        height: 18px;
    }
    .add-materials__button-text {
        font-size: 12px;
        line-height: 16px;
    }
    .add-materials__main-container {
        margin: 16px auto 0 auto;
        width: 100%;
    }
    .add-materials__heading-container {
        flex-direction: column;
        margin: 0 auto;
        width: 100%;
    }
    .add-materials__heading-input-container {
        display: flex;
        margin: 0 auto;
        width: 100%;
    }
    .add-materials__heading-input {
        width: 100%;
        height: 40px;
        font-size: 14px;
        line-height: 18px;
        padding: 0 16px;
        margin: 0 auto;
    }
    .add-materials__heading-input-red-star {
        display: none;
    }
    .add-materials__delete-container {
        display: none;
    }
    .add-materials__delete-container-mobile {
        display: flex;
    }
    .add-materials__select-container {
        flex-direction: column;
        margin: 16px auto 0 auto;
        width: 100%;
    }
    .add-materials__select-types-container {
        margin: 0 auto;
        width: 100%;
        height: 40px;
        cursor: default;
    }
    .add-materials__select-types-value {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: auto auto auto 16px;
    }
    .add-materials__select-types-arrow {
        width: 14px;
        height: 14px;
        margin: auto 16px auto auto;
    }
    .add-materials__types-container {
        top: 48px;
        left: 0;
        width: 100%;
    }
    .add-materials__type-container {
        height: 40px;
    }
    .add-materials__type {
        font-size: 14px;
        line-height: 18px;
        margin: auto auto auto 16px;
    }
    .add-materials__link-input {
        width: 100%;
        height: 40px;
        font-size: 14px;
        line-height: 18px;
        margin: 16px auto 0;
    }
    .add-materials__file-add-container {
        margin: 16px auto 0 auto;
        width: 100%;
        flex-direction: column;
    }
    .add-materials__file-add-input-container {
        margin: 0 auto;
        width: 100%;
    }
    .add-materials__file-add-input-file-name-container {
        width: 100%;
        height: 40px;
    }
    .add-materials__file-add-input-file-name-text {
        font-size: 14px;
        line-height: 18px;
        margin: auto auto auto 16px;
        max-width: 200px;
    }
    .add-materials__file-add-input-button {
        width: 140px;
        height: 40px;
    }
    .add-materials__file-add-input-button-text {
        font-size: 14px;
        line-height: 18px;
    }
    .add-materials__success-container {
        margin: 8px auto 0 0;
        width: auto;
    }
    .add-materials__success-icon {
        width: 14px;
        height: 14px;
    }
    .add-materials__success-label {
        font-size: 12px;
        line-height: 16px;
    }
}
@media (max-width: 680px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 150px;
    }
}
@media (max-width: 570px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 100px;
    }
}
@media (max-width: 500px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 250px;
    }
}
@media (max-width: 440px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 200px;
    }
}
@media (max-width: 375px) {
    .add-materials__file-add-input-file-name-text {
        max-width: 180px;
    }
}