.auth-two-factor-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 67;
    width: 100%;
    height: 100vh;
    background: rgba(54, 58, 72, 0.08);
}
.auth-two-factor {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 56px 56px;
    box-shadow: 0 8px 22px rgba(188, 188, 188, 0.25);
    background-color: #FFFFFF;
    border-radius: 8px;
    position: fixed;
    max-height: 100%;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.auth-two-factor__title {
    font-size: 32px;
    line-height: 133%;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    margin-bottom: 32px;
}
.auth-two-factor-form {
    display: flex;
    flex-direction: column;
    position: relative;
}
.auth-two-factor-form__label {
    font-size: 18px;
    font-weight: 400;
    line-height: 135%;
    color: rgba(54, 59, 77, 1);
    margin-bottom: 16px;
}
.auth-two-factor-form__input {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid rgba(54, 59, 77, 0.3);
    padding: 15px 24px;
    text-align: center;
    outline: none;
    color: rgba(54, 59, 77, 0.9);
}
.auth-two-factor-form__input::placeholder {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.3);
}
.auth-two-factor-form__error {
    text-align: left;
    color: #ff4970;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 6px;
    position: absolute;
    top: 95px;
}
.auth-two-factor-form__btn-block {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 48px;
}
.auth-two-factor-form__button {
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding: 15px 40px;
    background-color: #0084fe;
    cursor: pointer;
    border: 1px solid #0084fe;
}
.auth-two-factor-form__button:hover {
    background-color: #FFFFFF;
    border: 1px solid #0084fe;
    color: #0084fe;
}
.auth-two-factor-form__timer {
    text-align: center;
    color: #0084fe;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
@media (max-width: 1280px) {
    .auth-two-factor {
        padding: 45px 35px;
    }
    .auth-two-factor__title {
        font-size: 28px;
        margin-bottom: 24px;
    }
    .auth-two-factor-form__label {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .auth-two-factor-form__btn-block {
        margin-top: 32px;
    }
}

@media (max-width: 992px) {
    .auth-two-factor-form__button {
        padding: 12px 12px;
    }
}