.add-new-vote-template-protocol {
    display: flex;
    flex-direction: column;
    padding-top: 1.33em;
    border-top: 1px solid rgba(54, 59, 77, 0.2);
}
.add-new-vote-template-protocol__title {
    margin-bottom: 1.33em;
    font-weight: 600;
    line-height: 133%;
    font-size: 1.33rem;
    color: rgba(54, 59, 77, 1);
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 700px) {
    .add-new-vote-template-protocol__title {
        font-size: 18px;
        line-height: 133%;
        color: rgba(54, 59, 77, 1);
        font-weight: 400;
        margin-bottom: 16px;
    }
}